






















import { Component, Vue, Prop } from 'vue-property-decorator';
import { IPhaseDto } from '@/service-proxies/service-proxies.g';

const PHASES = {
    'Preparation': 2,
}

@Component({
    name: 'PhaseTotalCalculation',
})
export default class PhaseTotalCalculation extends Vue {
    @Prop()
    private scores: any;

    private get score(): number {
        const scores = this.$store.state.idea.score;
        let totalScore = (scores || []).length
            ? 1
            : 0
      
        ;(scores || []).forEach((score: any) => (
            totalScore = totalScore * score.value.map((score: any) => score.score).reduce((a: any, b: any) => a * b, 1)
        ))

        return totalScore;
    }

    get maxScore(): number {
        const phasePreparation: (phase: IPhaseDto) => boolean = phase => phase.id === PHASES['Preparation']

        const screens = this.$store.state.idea.screens.length
            ? this.$store.state.idea.screens
            : (this.$store.state.dashboard.phases.find(phasePreparation) || {}).screens || []

        let maxScores = screens
            .map((screen: any) => screen
                .questions.map((question: any) => (question.score || 1) * Math.max(...question.values.concat(1).map((value: any) => value.score || 1)))
            );
        maxScores = [].concat(...maxScores);

        return maxScores.reduce((a: any, b: any) => a * b, 1);
    }

    private get percentage(): number {
        return Math.round( (100 * this.score / this.maxScore) * 100 + Number.EPSILON ) / 100;
    }

    private get percentageColor(): string {
        if (this.percentage <= 25) return 'low';
        if (25 < this.percentage && this.percentage < 75) return 'medium';
        if (75 <= this.percentage) return 'high';

        return 'none';
    }
}

