
































import { Component, Emit, Vue,} from 'vue-property-decorator';

@Component({
    name: 'reject-request-modal',
})
export default class RejectRequestModal extends Vue {

    private comment = '';
    private submitDisabled = true;
    private isCommentValid = true;

    public open(): void {
        (this.$refs.rejectRequest as any)?.open();
    }

    private close(): void {
        (this.$refs.rejectRequest as any)?.close();
        this.comment = '';
        this.isCommentValid = true;
    }

    @Emit()
    confirm(): void {
        this.$emit('confirmRejection',this.comment);
        this.close();
    }

    private handleCommentInput(value: string): void {
        this.comment = value;
        this.submitDisabled = value.length === 0;
        this.isCommentValid = value.length > 0;
    }
}
