





















import { Component, Prop, Vue } from 'vue-property-decorator';
import { UserService } from '@/services/user-service';
import { UserFilterModel } from '@/models';

interface SearchOption {
    label: string | undefined;
    email: string | undefined;
    secondaryLabel: string | undefined;
    value: string | undefined;
}

@Component({
    name: 'project-manager-selector',
})

export default class ProjectManagerSelector extends Vue {
    @Prop({required: true, default: null })
    readonly projectManagerId!: string;

    @Prop({required: true, default: () => false })
    invalidProjectManager!: boolean;

    @Prop({required: true })
    editEnabled!: boolean;

    usersAreLoading = false;
    filteredUsers: Array<SearchOption> = [];
    selectedUser: SearchOption = {
        label: '',
        email: '',
        secondaryLabel: '',
        value: ''
    }

    private userFilterModel: UserFilterModel = {
        page: 1,
        size: 300,
        term: '',
        userGroupId: null,
        sortColumn: 1,
        sortDirection: 'Ascending',
    };

    async mounted(): Promise<void> {
        this.initInputData();
    }

    async initInputData(): Promise<void> {
        if (!this.projectManagerId) return;

        this.userFilterModel.term = this.projectManagerId;
        this.usersAreLoading = true;

        const users = (await new UserService().getAllPaged(this.userFilterModel)).result?.items;
        const user = users.find((user: any) => user.kid === this.projectManagerId);
        this.usersAreLoading = false;
        if (!user) return;

        this.selectedUser = {
            label: user.name || user.email || user.kid,
            email: user.email,
            secondaryLabel: user.kid,
            value: user.id.toString(),
        };
    }

    async onUserTypeAheadSearch(byTerm: string | undefined): Promise<void> {
        this.userFilterModel.term = byTerm || '';
        this.usersAreLoading = true;

        const users = (await new UserService().getAllPaged(this.userFilterModel)).result?.items;

        const usersMappedForDropdown: Array<SearchOption> = users.map((user: any) => ({
            label: user.name || user.email || user.kid,
            email: user.email,
            secondaryLabel: user.kid,
            value: user.id.toString(),
        }));

        this.usersAreLoading = false;

        this.filteredUsers = usersMappedForDropdown;
    }

    userSelectionChange(value: any): void {
        const userKid = value[0]?.secondaryLabel;
        this.$emit('projectManagerIdChange', userKid);
    }
}
