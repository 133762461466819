import {
    CostsAndBenefitsValueItemBenefitDto, CostsAndBenefitsValueItemRunCostDto, CostsAndBenefitsValueItemDto,
    CostsAndBenefitsEditDto, BenefitsDto, CostsDto, IdeaServiceRecipientBusinessUnitDto,
} from '@/service-proxies/service-proxies.g';
import moment from 'moment';

export default class CostsAndBenefitsTemplate {
    public static getEmptyCostsAndBenefits(): CostsAndBenefitsEditDto {
        return new CostsAndBenefitsEditDto({
            projectStartDate: new Date(),
            projectEndDate: new Date(CostsAndBenefitsTemplate.getFiveYearsLater()),
            strategicThemeId: undefined,
            deliveryStrategyId: undefined,
            deliveryTypeId: undefined,
            deliveryOrganizationId: undefined,
            serviceRecipientBusinessUnits: [new IdeaServiceRecipientBusinessUnitDto({
                serviceRecipientBusinessUnitId: undefined,
                invoicePercent: undefined
            })],
            costCenterId: undefined,
            discountRate: undefined,
            runCostAgreed: undefined,
            serviceEnablementContactComment: undefined,
            serviceEnablementContactId: undefined,
            serviceEnablementRequired: undefined,
            domainArchitectId: undefined,
            eamId: undefined,
            benefits: new BenefitsDto({
                indirectBenefits: [new CostsAndBenefitsValueItemBenefitDto({
                    benefitTypeId: undefined,
                    ...this.getEmptyValueItemBenefit()
                })],
                revenue: this.getEmptyValueItemBenefit(),
                opex: this.getEmptyValueItemBenefit(),
                capex: this.getEmptyValueItemBenefit(),
                itBenefits: this.getEmptyValueItemBenefit(),
            }),
            costs: new CostsDto({
                changeCost: this.getEmptyValueItemBenefit(),
                runCosts: [new CostsAndBenefitsValueItemRunCostDto({
                    costTypeId: undefined,
                    ...this.getEmptyValueItemBenefit()
                })]
            }),
            projectManagerId: undefined,
        })
    }

    public static getEmptyValueItemBenefit(): CostsAndBenefitsValueItemDto {
        return new CostsAndBenefitsValueItemDto({
            comment: undefined,
            year0: undefined,
            year1: undefined,
            year2: undefined,
            year3: undefined,
            year4: undefined,
            year5: undefined,
        })
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    public static mapCostsAndBenefitsDto(costsAndBenefits: any, storeCostsAndBenefits: any): void {
        if (!(storeCostsAndBenefits && Object.keys(storeCostsAndBenefits).length)) {
            costsAndBenefits = CostsAndBenefitsTemplate.getEmptyCostsAndBenefits();
        } else {
            costsAndBenefits = storeCostsAndBenefits;
            costsAndBenefits.projectStartDate = costsAndBenefits.projectStartDate ?? new Date();
            costsAndBenefits.projectEndDate = costsAndBenefits.projectEndDate ?? new Date(CostsAndBenefitsTemplate.getFiveYearsLater());
            if (costsAndBenefits.benefits.indirectBenefits.length === 0) {
                costsAndBenefits.benefits.indirectBenefits = [new CostsAndBenefitsValueItemBenefitDto({
                    benefitTypeId: undefined,
                    ...CostsAndBenefitsTemplate.getEmptyValueItemBenefit()
                })];
            }
            if (costsAndBenefits.serviceRecipientBusinessUnits.length === 0) {
                costsAndBenefits.serviceRecipientBusinessUnits = [new IdeaServiceRecipientBusinessUnitDto({
                    serviceRecipientBusinessUnitId: undefined,
                    invoicePercent: undefined
                })];
            }
            costsAndBenefits.serviceEnablementContactComment = costsAndBenefits?.serviceEnablementContactComment ?? null,
            costsAndBenefits.serviceEnablementContactId = costsAndBenefits?.serviceEnablementContactId ?? null,
            costsAndBenefits.serviceEnablementRequired = costsAndBenefits?.serviceEnablementRequired ?? true,
            costsAndBenefits.benefits.revenue = costsAndBenefits.benefits.revenue ??  CostsAndBenefitsTemplate.getEmptyValueItemBenefit();
            costsAndBenefits.benefits.opex = costsAndBenefits.benefits.opex ?? CostsAndBenefitsTemplate.getEmptyValueItemBenefit();
            costsAndBenefits.benefits.capex = costsAndBenefits.benefits.capex ?? CostsAndBenefitsTemplate.getEmptyValueItemBenefit();
            costsAndBenefits.benefits.itBenefits = costsAndBenefits.benefits.itBenefits ?? CostsAndBenefitsTemplate.getEmptyValueItemBenefit();
            costsAndBenefits.costs.changeCost = costsAndBenefits.costs.changeCost ?? CostsAndBenefitsTemplate.getEmptyValueItemBenefit();
            if (costsAndBenefits.costs.runCosts.length === 0) {
                costsAndBenefits.costs.runCosts = [new CostsAndBenefitsValueItemRunCostDto({
                    costTypeId: undefined,
                    ...CostsAndBenefitsTemplate.getEmptyValueItemBenefit()
                })]
            }
        }
        return costsAndBenefits;
    }

    public static mapCostsAndBenefitsToDto(costsAndBenefits: CostsAndBenefitsEditDto): CostsAndBenefitsEditDto {
        return new CostsAndBenefitsEditDto({
            projectStartDate: costsAndBenefits?.projectStartDate
                ? new Date(costsAndBenefits?.projectStartDate)
                : undefined,
            projectEndDate: costsAndBenefits?.projectEndDate
                ? new Date(costsAndBenefits?.projectEndDate)
                : undefined,
            strategicThemeId: costsAndBenefits?.strategicThemeId,
            deliveryStrategyId: costsAndBenefits?.deliveryStrategyId,
            deliveryTypeId: costsAndBenefits?.deliveryTypeId,
            deliveryOrganizationId: costsAndBenefits?.deliveryOrganizationId,
            serviceRecipientBusinessUnits: costsAndBenefits?.serviceRecipientBusinessUnits?.map((unit: any) =>
                new IdeaServiceRecipientBusinessUnitDto(unit)),
            costCenterId: costsAndBenefits?.costCenterId,
            discountRate: costsAndBenefits?.discountRate,
            runCostAgreed: costsAndBenefits?.runCostAgreed,
            projectManagerId: costsAndBenefits?.projectManagerId,
            serviceEnablementContactComment: costsAndBenefits?.serviceEnablementContactComment,
            serviceEnablementContactId: costsAndBenefits?.serviceEnablementContactId,
            serviceEnablementRequired: costsAndBenefits?.serviceEnablementRequired,
            domainArchitectId: costsAndBenefits?.domainArchitectId,
            eamId: Number(costsAndBenefits?.eamId),
            benefits: new BenefitsDto({
                indirectBenefits: costsAndBenefits?.benefits?.indirectBenefits?.map((indirectBenefit: any) =>
                    new CostsAndBenefitsValueItemBenefitDto(indirectBenefit)),
                revenue: new CostsAndBenefitsValueItemDto(costsAndBenefits?.benefits?.revenue),
                opex: new CostsAndBenefitsValueItemDto(costsAndBenefits?.benefits?.opex),
                capex: new CostsAndBenefitsValueItemDto(costsAndBenefits?.benefits?.capex),
                itBenefits: new CostsAndBenefitsValueItemDto(costsAndBenefits?.benefits?.itBenefits),
            }),
            costs: new CostsDto({
                changeCost: new CostsAndBenefitsValueItemDto(costsAndBenefits?.costs?.changeCost) ,
                runCosts: costsAndBenefits?.costs?.runCosts?.map((runCost: any) =>
                    new CostsAndBenefitsValueItemRunCostDto(runCost)),
            }),
        })
    }

    public static getFiveYearsLater(): any {
        return moment().add(5, 'y');
    }
}
