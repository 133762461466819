
























































































































































































































import { SelectOption } from '@/models';
import { actions, getters } from '@/store/types';
import moment from 'moment';
import { Component, Prop, Vue,} from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
    name: 'actual-costs-table',
    computed: mapGetters({
        costTypes: getters.GET_COST_TYPES,
    }),
})
export default class ActualCostsTable extends Vue {

    @Prop({required: true, default: () => new Object()})
    actualCosts!: any;

    @Prop({required: true, default: null })
    projectStartDate!: any;

    @Prop({required: true, default: false})
    isReadonly!: boolean;

    costTypes!: Array<SelectOption>;
    private columnSize = 4;

    private async mounted(): Promise<void> {
        await this.$store.dispatch(actions.LOAD_COST_TYPES);
    }

    private getCostTypeName(optionId: number): string | undefined {
        return this.costTypes.filter((type: SelectOption) => type.value === optionId)[0]?.label;
    }

    private commonColumnHeader = {
        total: this.$t('costsAndBenefits.total')
    }

    private changeCostTableColumnHeaders = {
        empty: this.$t('closureReportForm.changeCost') as string,
    }

    private runCostTableColumnHeaders = {
        empty: this.$t('closureReportForm.runCost') as string,
        costTypeId: this.$t('closureReportForm.type') as string,
    }

    getYear(yearStep: number): string {
        return moment(this.projectStartDate || undefined).add(yearStep, 'y').year().toString();
    }

    get changeCosts(): any[] {
        return this.actualCosts?.changeCosts;
    }

    get runCosts(): any[] {
        return this.actualCosts?.runCosts;
    }

    get changeCostTableConfig(): any  {
        return {
            options: {
                sortable: []
            },
            columns: [
                this.changeCostTableColumnHeaders.empty,
                this.runCostTableColumnHeaders.costTypeId,
                this.getYear(0),
                this.getYear(1),
                this.getYear(2),
                this.getYear(3),
                this.getYear(4),
                this.commonColumnHeader.total,
            ],
            data: [{
                empty: '',
                costTypeId: 1,
                ...this.changeCosts,
                total: this.getSumOfLineCosts(this.changeCosts)
            }],
        }
    }

    get runCostTableConfig(): any  {
        return {
            options: {
                sortable: []
            },
            columns: [
                this.runCostTableColumnHeaders.empty,
                this.runCostTableColumnHeaders.costTypeId,
                this.getYear(0),
                this.getYear(1),
                this.getYear(2),
                this.getYear(3),
                this.getYear(4),
                this.commonColumnHeader.total,
            ],
            data: this.runCosts?.map((cost: any, index: number) => ({ index: index, ...cost, empty: '', total: this.getSumOfLineCosts(cost) }))
        }
    }

    private getInputValue(value: number): string {
        if(value)
            return value?.toString();
        return '0';
    }

    private getSumOfLineCosts(cost: any): number{
        return Number(cost?.year0) + Number(cost?.year1) + Number(cost?.year2) + Number(cost?.year3) + Number(cost?.year4);
    }

    private getChangeCostDefaultValue(valueToUpdate: string): void {
        if(this.actualCosts.changeCosts[valueToUpdate] === '')
            this.actualCosts.changeCosts[valueToUpdate] = 0;
    }

    private getRunCostDefaultValue(rowIndex: number, valueToUpdate: string): void {
        if(this.actualCosts.runCosts[rowIndex][valueToUpdate] === '')
            this.actualCosts.runCosts[rowIndex][valueToUpdate] = 0;
    }

    private getLongestCostTypeName(): string{
        return this.runCosts
            ?.map(runCost => this.getCostTypeName(runCost.costTypeId) ?? '')
            .reduce((max, el) => max === undefined || max.length < el.length ? el : max,'');
    }
}
