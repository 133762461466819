












































































import { Component, Prop, Vue } from 'vue-property-decorator';
import moment from 'moment';
import {  CostsAndBenefitsValueItemDto } from '@/service-proxies/service-proxies.g';
import Years from '@/components/screen/common/years';
import YearCalculations from './year-calculations';
import YearsDto from './years-dto';

class EmptyValueItemBenefit {
    year0: number | null = null;
    year1: number | null = null;
    year2: number | null = null;
    year3: number | null = null;
    year4: number | null = null;
    year5: number | null = null;
}

@Component({
    name: 'cost-benefit-overview',
})

export default class CostBenefitOverview extends Vue {
    @Prop({ default: () => new Date()  })
    projectStartDate!: Date;

    @Prop({required: true, default: () => new EmptyValueItemBenefit() })
    changeCost!: CostsAndBenefitsValueItemDto;

    @Prop({required: true, default: () => [new EmptyValueItemBenefit()] })
    runCosts!: Array<CostsAndBenefitsValueItemDto>;

    @Prop({required: true, default: () => new EmptyValueItemBenefit() })
    revenue!: CostsAndBenefitsValueItemDto;

    @Prop({required: true, default: () => new EmptyValueItemBenefit() })
    opex!: CostsAndBenefitsValueItemDto;

    @Prop({required: true, default: () => new EmptyValueItemBenefit() })
    capex!: CostsAndBenefitsValueItemDto;

    @Prop({required: true, default: () => new EmptyValueItemBenefit() })
    itBenefits!: CostsAndBenefitsValueItemDto;

    getYear(yearStep: number): string {
        return moment(this.projectStartDate || undefined).add(yearStep, 'y').year().toString();
    }

    private columns = {
        category: this.$t('costsAndBenefits.category') as string,
        total: this.$t('costsAndBenefits.total') as string,
        npv: this.$t('costsAndBenefits.netValue') as string,
    }

    get tableOptions(): any {
        return {
            options: {
                sortable: [],
            },
            columns: [
                this.columns.category,
                this.getYear(0),
                this.getYear(1),
                this.getYear(2),
                this.getYear(3),
                this.getYear(4),
                this.columns.total,
                this.columns.npv
            ],
            data: [
                {
                    [this.columns.category]: this.$t('costsAndBenefits.originalChangeCostsk€'),
                    [this.getYear(0)]: this.changeCost[Years.year0],
                    [this.getYear(1)]: this.changeCost[Years.year1],
                    [this.getYear(2)]: this.changeCost[Years.year2],
                    [this.getYear(3)]: this.changeCost[Years.year3],
                    [this.getYear(4)]: this.changeCost[Years.year4],
                    [this.columns.total]: this.totalCosts,
                    [this.columns.npv]: this.netYearValues,
                },
                {
                    [this.columns.category]: this.$t('costsAndBenefits.originalRunCostsk€'),
                    [this.getYear(0)]: this.runCostsTotal[Years.year0],
                    [this.getYear(1)]: this.runCostsTotal[Years.year1],
                    [this.getYear(2)]: this.runCostsTotal[Years.year2],
                    [this.getYear(3)]: this.runCostsTotal[Years.year3],
                    [this.getYear(4)]: this.runCostsTotal[Years.year4],
                    [this.columns.total]: this.totalCosts,
                    [this.columns.npv]: this.netYearValues,
                },
                {
                    [this.columns.category]: this.$t('costsAndBenefits.estimatedSavingsRevItk€'),
                    [this.getYear(0)]: this.itBenefits[Years.year0],
                    [this.getYear(1)]: this.itBenefits[Years.year1],
                    [this.getYear(2)]: this.itBenefits[Years.year2],
                    [this.getYear(3)]: this.itBenefits[Years.year3],
                    [this.getYear(4)]: this.itBenefits[Years.year4],
                    [this.columns.total]: this.totalBenefits,
                    [this.columns.npv]: this.netYearValues,
                },
                {
                    [this.columns.category]: this.$t('costsAndBenefits.estimatedSavingsRevBusinessk€'),
                    [this.getYear(0)]: this.tangibleBusinessBenefits[Years.year0],
                    [this.getYear(1)]: this.tangibleBusinessBenefits[Years.year1],
                    [this.getYear(2)]: this.tangibleBusinessBenefits[Years.year2],
                    [this.getYear(3)]: this.tangibleBusinessBenefits[Years.year3],
                    [this.getYear(4)]: this.tangibleBusinessBenefits[Years.year4],
                    [this.columns.total]: this.totalBenefits,
                    [this.columns.npv]: this.netYearValues,
                },
            ]
        }
    }

    get runCostsTotal(): YearsDto{
        const runCostsTotal = new YearsDto();
        this.runCosts.forEach((runCost: any)  => {
            YearCalculations.addYearValues(runCostsTotal, runCost);
        });
        return runCostsTotal;
    }

    get totalCosts(): any{
        const totalCosts = new YearsDto();
        YearCalculations.addYearValues(totalCosts, this.runCostsTotal);
        YearCalculations.addYearValues(totalCosts, this.changeCost);
        return YearCalculations.sumOfYearValues(totalCosts);
    }

    get totalBenefits(): any{
        const totalBenefits = new YearsDto();
        YearCalculations.addYearValues(totalBenefits, this.tangibleBusinessBenefits);
        YearCalculations.addYearValues(totalBenefits, this.itBenefits);
        return YearCalculations.sumOfYearValues(totalBenefits);
    }

    get netYearValues(): number | null{
        return this.totalBenefits - this.totalCosts;
    }

    get tangibleBusinessBenefits(): YearsDto{
        const benefits = new YearsDto();
        YearCalculations.addYearValues(benefits, this.revenue);
        YearCalculations.addYearValues(benefits, this.opex);
        YearCalculations.addYearValues(benefits, this.capex);
        return benefits;
    }

    formatThousands(value: any): any {
        if (value == null || value == '') return '';
        return Math.floor(Number(value) / 1000);
    }
}
