import {
    RealizedAndScaledBenefitsGetDto, CostsAndBenefitsValueItemDto, RealizedAndScaledBenefitsBaseDto, CostsAndBenefitsValueItemBenefitDto, CostsAndBenefitsValueItemRunCostDto, Status
} from '@/service-proxies/service-proxies.g';

export default class BenefitsTemplate {

    private static canUpdateNewDeliveryInfo = false;

    public static mapDefaultBenefitsFields(benefits: RealizedAndScaledBenefitsGetDto, statusId?: number): RealizedAndScaledBenefitsGetDto {
        if(statusId)
            this.canUpdateNewDeliveryInfo = statusId >= Status.DeliveryApproved && statusId <= Status.InImplementation;

        return new RealizedAndScaledBenefitsGetDto({
            realized: new RealizedAndScaledBenefitsBaseDto({
                indirectBenefits: (benefits?.realized?.indirectBenefits?.length ?? 0) > 0
                    ? benefits?.realized?.indirectBenefits
                    : [new CostsAndBenefitsValueItemBenefitDto({
                        benefitTypeId: undefined,
                        comment: undefined,
                    })],
                revenue: this.getValueItemBenefit(benefits?.realized?.revenue),
                opex: this.getValueItemBenefit(benefits?.realized?.opex),
                capex: this.getValueItemBenefit(benefits?.realized?.capex),
            }),
            scaled: new RealizedAndScaledBenefitsBaseDto({
                revenue: this.getValueItemBenefit(benefits?.scaled?.revenue),
                opex: this.getValueItemBenefit(benefits?.scaled?.opex),
                capex: this.getValueItemBenefit(benefits?.scaled?.capex),
                indirectBenefits: (benefits?.scaled?.indirectBenefits?.length ?? 0) > 0
                    ? benefits?.scaled?.indirectBenefits
                    : [new CostsAndBenefitsValueItemBenefitDto({
                        benefitTypeId: undefined,
                        comment: undefined,
                    })],
            }),
            newChangeCosts: this.getValueItemBenefit(benefits?.newChangeCosts),
            newRunCosts: (benefits?.newRunCosts?.length ?? 0) > 0
                ? benefits?.newRunCosts
                : [new CostsAndBenefitsValueItemRunCostDto({
                    costTypeId: undefined,
                    comment: undefined,
                    year0: 0,
                    year1: 0,
                    year2: 0,
                    year3: 0,
                    year4: 0,
                    year5: 0,
                })],
        })
    }

    public static getValueItemBenefit(value: CostsAndBenefitsValueItemDto | undefined): CostsAndBenefitsValueItemDto {
        if (value != null) return value;
        return new CostsAndBenefitsValueItemDto({
            comment: undefined,
            year0: 0,
            year1: 0,
            year2: 0,
            year3: 0,
            year4: 0,
            year5: 0,
        })
    }

    public static mapRealizedAndScaledBenefitsToDto(benefits: RealizedAndScaledBenefitsGetDto, statusId?: number): RealizedAndScaledBenefitsGetDto {
        if(statusId)
            this.canUpdateNewDeliveryInfo = statusId >= Status.DeliveryApproved && statusId <= Status.InImplementation;

        return new RealizedAndScaledBenefitsGetDto({
            realized: new RealizedAndScaledBenefitsBaseDto({
                indirectBenefits: benefits?.realized?.indirectBenefits?.map((indirectBenefit: any) =>
                    new CostsAndBenefitsValueItemBenefitDto(indirectBenefit)),
                revenue: new CostsAndBenefitsValueItemDto(benefits?.realized?.revenue),
                opex: new CostsAndBenefitsValueItemDto(benefits?.realized?.opex),
                capex: new CostsAndBenefitsValueItemDto(benefits?.realized?.capex),
            }),
            scaled: new RealizedAndScaledBenefitsBaseDto({
                indirectBenefits: benefits?.scaled?.indirectBenefits?.map((indirectBenefit: any) =>
                    new CostsAndBenefitsValueItemBenefitDto(indirectBenefit)),
                revenue: new CostsAndBenefitsValueItemDto(benefits?.scaled?.revenue),
                opex: new CostsAndBenefitsValueItemDto(benefits?.scaled?.opex),
                capex: new CostsAndBenefitsValueItemDto(benefits?.scaled?.capex),
            }),
            ...this.canUpdateNewDeliveryInfo && {newChangeCosts: new CostsAndBenefitsValueItemBenefitDto(benefits?.newChangeCosts)},
            ...this.canUpdateNewDeliveryInfo && {newRunCosts: benefits?.newRunCosts?.map((runCost: any) =>
                new CostsAndBenefitsValueItemRunCostDto(runCost))},
        })
    }
}
