import { UsersPaged, ApiResponse, UserFilterModel, User } from '@/models';
import { ApiResponseList } from '@/models/api-response';
import Vue from 'vue';

export class UserService {
    private endpoint = 'users';

    public async getAllPaged(filter: UserFilterModel): Promise<ApiResponse<UsersPaged>> {
        // eslint-disable-next-line
        const querystring = require('querystring');
        const query = querystring.stringify(filter);
        return (await Vue.prototype.$sdk?.$http?.$generic.request
            .api.get(`${this.endpoint}` + (query ? '?' + query : ''))).data;
    }

    public async getById(userId: number): Promise<ApiResponse<User>> {
        return (await Vue.prototype.$sdk?.$http?.$generic.request
            .api.get(`${this.endpoint}/${userId}`)).data;
    }

    public async getAllForUserGroup(groupId: number): Promise<ApiResponse<ApiResponseList<User>>> {
        return (await Vue.prototype.$sdk?.$http?.$generic.request
            .api.get(`${this.endpoint}/user-groups/${groupId}`)).data;
    }

    public async getAllAvailableForUserGroup(groupId: number): Promise<ApiResponse<ApiResponseList<User>>> {
        return (await Vue.prototype.$sdk?.$http?.$generic.request
            .api.get(`${this.endpoint}/user-groups/${groupId}/available`)).data;
    }
}
