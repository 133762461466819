














































import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: 'widget-evaluation',
})
export default class WidgetEvaluation extends Vue {
    $store: any;

    private get currentPhase(): string {
        return this.$store.state.ideas.idea.phase || {};
    }

    private get phases(): any[] {
        return this.$store.state.dashboard.phases.length
            ? this.$store.state.dashboard.phases.slice(1, this.$store.state.dashboard.phases.length - 1)
            : [ { name: 'not' } ]
    }
}

