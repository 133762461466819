

























import { Component, Prop, Vue } from 'vue-property-decorator';
import moment from 'moment';

@Component({
    name: 'years-header',
})
export default class YearsHeader extends Vue{
    @Prop({required: false, default: '' })
    readonly title!: string;

    @Prop({required: false, default: () => new Date()})
    projectStartDate!: any;

    getYear(yearStep: number): string {
        return moment(this.projectStartDate || undefined).add(yearStep, 'y').year().toString();
    }
}
