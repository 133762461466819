











































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import { IdeaGetResponse, IStatusDto, IPhaseDto, OnePagerQuestionDto } from '@/service-proxies/service-proxies.g';
import { getters } from '@/store/types';

import { UserFilterModel } from '@/models';
import { UserService } from '@/services/user-service';

import { STATUS } from '@/components/status/status.vue';
import Evaluation from '@/components/widgets/evaluation.vue';
import CostBenefitOverview from '@/components/screen/costs-and-benefits/cost-benefit-overview.vue';
import Permissions from '@/utils/helpers/permisssions';

const ignoreAnswers = [
    'Required Next Steps and Updates from COODE',
]

@Component({
    name: 'one-pager-preview',
    components: {
        Evaluation,
        CostBenefitOverview,
    },
    computed: {
        ...mapState({
            idea: (state: any) => state.ideas.idea,
            statuses: (state: any) => state.dashboard.statuses,
            costsAndBenefits: (state: any) => state.ideas.idea.costsAndBenefits,
        }),
        ...mapGetters({
            costsAndBenefits: getters.GET_IDEA_COSTS_AND_BENEFITS,
        }),
    }
})
export default class OnePagerPreview extends Vue {
    private idea!: IdeaGetResponse;
    private statuses!: IStatusDto[];
    private answers: any[] = [];
    private initiatorObject: {label?: string; email?: string; secondaryLabel?: string; value?: string} | undefined = { label: 'N/A' }
    costsAndBenefits!: any;
    /**
     * List of all questions.
     */
    @Prop()
    private allQuestions!: any[];

    @Prop()
    private relatedUseCaseName!: string;

    private async created(): Promise<void> {
        await this.initiator()
    }

    private get questions(): OnePagerQuestionDto[] | undefined {
        return (this.idea.onePagerQuestions || []).filter(question => question.title && !~ignoreAnswers.indexOf(question.title));
    }

    get getStatusLabel(): string {
        const status = this.statuses.find((s: IStatusDto) => s.id === this.idea.statusId);
        return status && status.name ? status.name : '';
    }

    get getCreatedAt(): string {
        return this.idea.createdAt ? this.idea.createdAt.toDateString() : '';
    }

    private get statusColor(): any {
        return {
            backgroundColor: (STATUS as any)[this.getStatusLabel],
        }
    }

    private get currentPhase(): string {
        return this.$store.state.ideas.idea.phase || {};
    }

    private get phases(): any[] {
        return this.$store.state.dashboard.phases.length
            ? this.$store.state.dashboard.phases.slice(0, this.$store.state.dashboard.phases.length - 1)
                .map((phase: IPhaseDto) => ({ ...phase, name: (phase.name || '').replace('/', ' / ') }))
            : [ { name: 'not' } ]
    }

    private async initiator(): Promise<string | undefined> {
        const userIdentifier = this.idea.createdByEmail || this.idea.createdBy || this.idea.createdByFullName

        if (userIdentifier) {
            const match = await this.getUserValueForTerm(userIdentifier)
            if (match && match.value) {
                this.initiatorObject = match
                return match.value
            }
        }

        return 'N/A'
    }

    private async getUserValueForTerm(term: string): Promise<{label?: string; email?: string; secondaryLabel?: string; value?: string} | undefined> {
        if (term && term.length > 0) {
            let termToSearch = ''

            if (term.split('@').length > 0) {
                const separatedEmailArray = term.split('@')
                termToSearch = separatedEmailArray[0]
            } else {
                termToSearch = term
            }

            const userFilterModel: UserFilterModel = {
                page: 1,
                size: 1,
                term: termToSearch,
                userGroupId: null,
                sortColumn: 1,
                sortDirection: 'Ascending',
            };


            const users = await new UserService().getAllPaged(userFilterModel);

            if (users && users.result && users.result.items && users.result.items.length > 0) {
                const user = users.result.items[0]

                return {
                    label: user.name || user.email || user.kid || 'N/A',
                    email: user.email,
                    secondaryLabel: user.kid,
                    value: `${user.id}`,
                }
            }
        }

        return undefined
    }

    get canViewIdeaCostsAndBenefitsSummary(): boolean {
        return Permissions.canViewIdeaCostsAndBenefitsSummary();
    }

    get displayCostBenefitOverview(): boolean {
        return this.costsAndBenefits
            && this.costsAndBenefits.projectStartDate != null
            && this.costsAndBenefits.costs.changeCost != null
            && this.costsAndBenefits.costs.runCosts.length
            && this.costsAndBenefits.benefits.revenue != null
            && this.costsAndBenefits.benefits.opex != null
            && this.costsAndBenefits.benefits.capex != null
            && this.costsAndBenefits.benefits.itBenefits != null
    }
}

