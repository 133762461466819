var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-card"},[_c('div',{staticClass:"card-header"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('closureReportForm.tangibleBenefits'))+" ")])]),_c('pui-table',{attrs:{"vendor-options":_vm.tableConfig},scopedSlots:_vm._u([{key:_vm.tableColumnHeaders.empty,fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex-row flex-center bold"},[_vm._v(" "+_vm._s(row.empty)+" ")])]}},{key:_vm.getYear(0),fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.year0)+" ")]}},{key:_vm.getYear(1),fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.year1)+" ")]}},{key:_vm.getYear(2),fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.year2)+" ")]}},{key:_vm.getYear(3),fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.year3)+" ")]}},{key:_vm.getYear(4),fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.year4)+" ")]}},{key:_vm.tableColumnHeaders.total,fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.total)+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }