

































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { mapGetters, mapActions } from 'vuex';
import { getters, actions } from '@/store/types';
import TangibleBenefitsBusiness from '@/components/screen/common/tangible-benefits-business.vue';
import IndirectBenefits from '@/components/screen/common/indirect-benefits.vue'
import BenefitsTemplate from './benefits-template';
import moment from 'moment';
import Permissions from '@/utils/helpers/permisssions';
import { RealizedAndScaledBenefitsGetDto, CostsAndBenefitsValueItemBenefitDto, Status } from '@/service-proxies/service-proxies.g'
import DeliveryInformation from './delivery-information.vue';
import Loaders from '../costs-and-benefits/loaders';

@Component({
    name: 'realized-benefits',
    components: {
        TangibleBenefitsBusiness,
        IndirectBenefits,
        DeliveryInformation
    },
    computed: mapGetters({
        storeRealizedAndScaledBenefits: getters.GET_IDEA_REALIZED_AND_SCALED_BENEFITS,
        projectStartDate: getters.GET_IDEA_COSTS_AND_BENEFITS_START_DATE,
        ideaStatusId: getters.GET_IDEA_STATUS_ID,
    }),
    methods: {
        ...mapActions({
            setIdeaRealizedAndScaledBenefits: actions.EDIT_IDEA_REALIZED_AND_SCALED_BENEFITS,
        })
    }
})
export default class RealizedAndScaledBenefits extends (Loaders) {
    storeRealizedAndScaledBenefits!: RealizedAndScaledBenefitsGetDto;
    projectStartDate!: any;
    ideaStatusId!: number;
    realizedAndScaledBenefits: any = {};
    setIdeaRealizedAndScaledBenefits!: (value: any) => any;

    @Prop({required: true, default: false })
    readonly showErrorsIfExists!: boolean;

    created(): void {
        this.realizedAndScaledBenefits = BenefitsTemplate.mapDefaultBenefitsFields(this.storeRealizedAndScaledBenefits, this.ideaStatusId);
    }

    @Watch('realizedAndScaledBenefits', { deep: true })
    realizedAndScaledBenefitsChanged(realizedAndScaledBenefitsChanged: any): void {
        this.setIdeaRealizedAndScaledBenefits(realizedAndScaledBenefitsChanged);
    }

    get yearsForecast(): number {
        const projectStartDateYear = moment(this.projectStartDate).year();
        const projectEndDateYear = moment(this.getFiveYearsLater()).year();
        return projectEndDateYear - projectStartDateYear;
    }

    private getFiveYearsLater(): any {
        return moment().add(5, 'y');
    }

    get userIsAdminOrProjectManager(): boolean {
        return Permissions.isAdmin() || Permissions.isProductOwnerOrProjectManager();
    }

    get canEditDeliveryInformation(): boolean {
        const statusIsCorrect = this.ideaStatusId >= Status.DeliveryApproved && this.ideaStatusId <= Status.InImplementation;
        return this.userIsAdminOrProjectManager && statusIsCorrect;
    }

    get canEditRealizedBenefits(): boolean {
        const statusIsCorrect = this.ideaStatusId === Status.InImplementation;
        return this.userIsAdminOrProjectManager && statusIsCorrect;
    }

    get canEditScaledBenefits(): boolean {
        return Permissions.canEditScaledBenefits();
    }

    get isRealizedBenefitsInvalid(): boolean {
        return ['opex','capex','revenue']
            .some((benefit: any) => Object.values(this.realizedAndScaledBenefits.realized[benefit])
                .every((item: any) => item === null || item === 0 || item?.length === 0 ))
                    || this.realizedAndScaledBenefits.realized.indirectBenefits.some((benefit: any) => !benefit.benefitTypeId);
    }

    get isValidRealizedAndScaledBenefits(): boolean {
        const invalidRealizedIndirectBenefits = this.realizedAndScaledBenefits.realized.indirectBenefits
            .some((indirectBenefit: CostsAndBenefitsValueItemBenefitDto) => indirectBenefit.comment && indirectBenefit.benefitTypeId == null)
        const invalidScaledIndirectBenefits = this.realizedAndScaledBenefits.scaled.indirectBenefits
            .some((indirectBenefit: CostsAndBenefitsValueItemBenefitDto) => indirectBenefit.comment && indirectBenefit.benefitTypeId == null)

        const validRealizedAndScaledBenefits = !invalidRealizedIndirectBenefits && !invalidScaledIndirectBenefits;

        return this.ideaStatusId >= Status.InImplementation ?
            validRealizedAndScaledBenefits && !this.isRealizedBenefitsInvalid :
            validRealizedAndScaledBenefits
        ;
    }
}
