





































import { Component, Vue } from 'vue-property-decorator';


@Component({
    name: 'unsaved-changes-modal',
})

export default class UnsavedChangesModal extends Vue {

    public open(): void {
        (this.$refs.changesModal as any)?.open();
    }

    public close(): void {
        (this.$refs.changesModal as any)?.close();
    }

}

