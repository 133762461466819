





















































import { SelectOption, UserFilterModel } from '@/models';
import { UserService } from '@/services/user-service';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Permissions from '@/utils/helpers/permisssions';

const userService = new UserService();

@Component({
    name: 'application-support',
})

export default class ApplicationSupport extends Vue {
    @Prop({required: true})
    private eamId!: number;

    @Prop({required: true})
    private domainArchitectId!: string;

    @Prop({required: true })
    editEnabled!: boolean;

    @Prop({required: true })
    invalidDomainArchitect!: boolean;

    private filteredUsers: Array<SelectOption> = [];
    private usersAreLoading = false;
    private domainArchitect: SelectOption = new SelectOption();
    private eamIdValue: number | null | string = this.eamId ?? '';

    private userFilterModel: UserFilterModel = {
        page: 1,
        size: 300,
        term: '',
        userGroupId: null,
        sortColumn: 1,
        sortDirection: 'Ascending',
    };

    private async mounted(): Promise<void> {
        await this.initInputData();
        this.eamIdValue = this.eamId;
    }

    private async initInputData(): Promise<void> {
        if (!this.domainArchitectId) return;

        this.userFilterModel.term = this.domainArchitectId;
        this.usersAreLoading = true;

        const users = (await userService.getAllPaged(this.userFilterModel)).result?.items;
        const user = users.find((user: any) => user.kid === this.domainArchitectId);
        this.usersAreLoading = false;
        if (!user) return;

        this.domainArchitect = {
            label: user.name || user.email || user.kid,
            email: user.email,
            secondaryLabel: user.kid,
            value: user.id.toString(),
        };
    }

    private handleDomainArchitectChanged(value: any): void {
        this.$emit('domainArchitectIdChanged', value[0]?.secondaryLabel);
        if(!Permissions.loggedInKidIs(value[0]?.secondaryLabel)) {
            this.$emit('eamIdChanged', null);
            this.eamIdValue = null;
        }

    }

    private handleEamIdChanged(value: any): void {
        this.eamIdValue = value;
        this.$emit('eamIdChanged', value);
    }

    private async onUserTypeAheadSearch(byTerm: string | undefined): Promise<void> {
        this.userFilterModel.term = byTerm || '';
        this.usersAreLoading = true;

        const users = (await new UserService().getAllPaged(this.userFilterModel)).result?.items;

        const usersMappedForDropdown: Array<SelectOption> = users.map((user: any) => ({
            label: user.name || user.email || user.kid,
            email: user.email,
            secondaryLabel: user.kid,
            value: user.id.toString(),
        }));

        this.usersAreLoading = false;

        this.filteredUsers = usersMappedForDropdown;
    }

    get isEamIdDisabled(): boolean {
        return !Permissions.loggedInKidIs(this.domainArchitectId) && !Permissions.isAdmin();
    }

    private get isReadonly(): boolean {
        return !Permissions.isAdmin() && !this.editEnabled;
    }
}
