





















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { CostsAndBenefitsValueItemBenefitDto } from '@/service-proxies/service-proxies.g';

import YearsHeader from './years-header.vue';
import YearsInputs from './years-inputs.vue';

@Component({
    name: 'tangible-benefits-business',
    components: {
        YearsHeader,
        YearsInputs,
    },
})

export default class TangibleBenefitsBusiness extends Vue {
    @Prop({required: true, default: null })
    projectStartDate!: any;

    @Prop({required: true, default: null })
    revenue!: CostsAndBenefitsValueItemBenefitDto;

    @Prop({required: true, default: null })
    opex!: CostsAndBenefitsValueItemBenefitDto;

    @Prop({required: true, default: null })
    capex!: CostsAndBenefitsValueItemBenefitDto;

    @Prop({required: true, default: null })
    yearsForecast!: number;

    @Prop({required: true })
    editEnabled!: boolean;

    @Watch('revenue', { deep: true })
    revenueChanged(newValue: any): void {
        this.$emit('revenueChange', newValue);
    }

    @Watch('opex', { deep: true })
    opexChanged(newValue: any): void {
        this.$emit('opexChange', newValue);
    }

    @Watch('capex', { deep: true })
    capexChanged(newValue: any): void {
        this.$emit('capexChange', newValue);
    }
}
