





































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { EventBus } from '@/utils';
import {
    Status,
    IScreenDto,
    QuestionDto,
    AnswerNestedCreateDto,
    AnswerNestedEditDto,
    AnswerValueNestedCreateDto,
    AnswerValueNestedEditDto,
} from '@/service-proxies/service-proxies.g';
import { UserService } from '@/services/user-service';
import { UserFilterModel } from '@/models';

import formFieldMap from './formFieldMap';
import { actions, mutations } from '@/store/types';

Component.registerHooks([
    'beforeRouteLeave',
]);

@Component({
    name: 'idea-new',
})
export default class Submit extends Vue {
    $store: any;

    private submitted = false;

    answers: AnswerNestedCreateDto[] = [];

    initialAnswers = '';

    currentScreen: IScreenDto = {};

    private validating = false;

    private unsavedDataDismissed = false;

    private filteredUsers: {label: string; email: string; secondaryLabel: string; value: string}[] = []
    private usersAreLoading = false
    private downloadedUsers: {label: string; email: string; secondaryLabel: string; value: string}[] = []

    private async onUserTypeAheadSearch(e: any): Promise<void> {
        this.filteredUsers = await this.usersFiltered(e)
    }

    private onUserTypeAheadAbort(): void {
        this.usersAreLoading = false
    }

    private async usersFiltered(byTerm: string | undefined): Promise<{label: string; email: string; secondaryLabel: string; value: string}[]> {
        const userFilterModel: UserFilterModel = {
            page: 1,
            size: 300,
            term: byTerm ? byTerm : '',
            userGroupId: null,
            sortColumn: 1,
            sortDirection: 'Ascending',
        };

        this.usersAreLoading = true

        const users = await new UserService().getAllPaged(userFilterModel);
        let userList = [];
        userList = users && users.result ? users.result.items : [];

        const usersMappedForDropdown: {label: string; email: string; secondaryLabel: string; value: string}[] = userList.map((user: any) => ({
            label: user.name || user.email || user.kid,
            email: user.email,
            secondaryLabel: user.kid,
            value: `${user.id}`
        }))

        this.downloadedUsers = this.downloadedUsers.concat(usersMappedForDropdown)
        this.downloadedUsers = [...new Set(this.downloadedUsers)]

        this.usersAreLoading = false

        return usersMappedForDropdown
    }

    private get disabled(): boolean {
        return this.$store.state.ideas.idea.isReadOnly === true;
    }

    private get noUnsavedData(): boolean {
        return JSON.stringify(this.answers) === this.initialAnswers || (!this.answers.length && this.initialAnswers === '');
    }

    public async getData(): Promise<void> {
        const ideaId = this.$store.state.ideas.idea.id;
        await this.$store.dispatch('idea/getSubmitForm', ideaId);
        this.currentScreen = this.steps[0]
    }

    async created(): Promise<void> {
        this.$store.commit(mutations.NEW_IDEA);
        await this.getData();

        for (const step of this.steps) {
            if (step.questions && step.questions.length > 0) {
                for (const question of step.questions) {
                    if(this.formFieldIsUserDropdown(question)) {
                        await this.downloadUserDropdownAnswer(question)
                    }
                }
            }
        }
    }

    private get showWizard(): boolean {
        return this.$route.name === 'Submit' || this.$store.state.ideas.idea.id
    }

    get steps(): IScreenDto[] {
        return this.$store.state.idea.screens.filter((screen: IScreenDto) => screen.limitedToStatusId === Status.Draft)
    }

    get lastStep(): boolean {
        const currentStepId = this.currentScreen ? this.currentScreen.id : undefined
        const lastStepId = currentStepId ? this.steps[this.steps.length - 1].id : -1

        return currentStepId === lastStepId
    }

    get questions(): QuestionDto[] | undefined {
        return this.currentScreen ? this.currentScreen.questions : []
    }

    // questions
    getAnswer(question: QuestionDto): string | undefined {
        const answers = this.answers
        const existingAnswer = answers.find((answer: any) => answer.questionId === question.id)

        if (existingAnswer) {
            return existingAnswer.content
        } else {
            return undefined
        }
    }

    getUserDropdownAnswer(question: QuestionDto): any | undefined {
        const answers = this.answers
        const existingAnswer = answers.find((answer: any) => answer.questionId === question.id)

        for (const user of this.downloadedUsers) {
            if (user && (user.secondaryLabel || user.label) && existingAnswer && existingAnswer.content && (user.secondaryLabel == existingAnswer.content || user.label == existingAnswer.content) ) {
                return user
            }
        }

        return undefined
    }

    setAnswer(question: QuestionDto, answer: string): void {
        const answers = this.answers
        const existingAnswer = answers.find((answer: any) => answer.questionId === question.id)

        if (existingAnswer) {
            existingAnswer.content = answer
        } else {
            answers.push(new AnswerNestedCreateDto({
                questionId: question.id,
                content: answer,
            }))
        }

        this.unsavedDataDismissed = false;
    }

    setUserDropdownAnswer(question: QuestionDto, users: any): void {
        if (users && users.length > 0) {
            const answers = this.answers
            const existingAnswer = answers.find((answer: any) => answer.questionId === question.id)

            if (existingAnswer) {
                existingAnswer.content = users[0].secondaryLabel
            } else {
                answers.push(new AnswerNestedCreateDto({
                    questionId: question.id,
                    content: users[0].secondaryLabel,
                }))
            }

        }

        this.unsavedDataDismissed = false;
    }

    getAnswerValue(question: QuestionDto, answerValue: any): string | boolean {
        const answers = this.answers
        const existingAnswer = answers.find((answer: any) => answer.questionId === question.id)
        const existingAnswerValue = existingAnswer ? existingAnswer.answerValues || [] : []

        if (answerValue === undefined) {
            return !!existingAnswerValue.length
        }

        return existingAnswerValue.find(i => i.valueId === answerValue.id) ? 'X' : ''
    }

    getCheckedForAnswer(question: QuestionDto, answerID: number): boolean {
        const currentAnswer = this.answers.find(answer => answer.questionId === question.id)
        let answerIsChecked = false

        if (currentAnswer !== undefined) {
            const aValues = currentAnswer.answerValues
            if (aValues !== undefined && aValues.length > 0) {
                answerIsChecked = aValues.find(answerValue => answerValue.valueId === answerID) ? true : false
            }
        }

        return answerIsChecked
    }

    getCheckedForRadio(question: QuestionDto, answer: any): boolean {
        return (this.answers.find(answer => answer.questionId === question.id) || {}).content == answer
    }

    setAnswerValue(question: QuestionDto, answerValue: any, value: boolean): void {
        const answers = this.answers
        const existingAnswer = answers.find((answer: any) => answer.questionId === question.id)
        let existingAnswerValue = existingAnswer ? existingAnswer.answerValues || [] : []

        if (value === undefined) {
            existingAnswerValue = [new AnswerValueNestedCreateDto({ valueId: answerValue })]
        } else {
            !value
                ? existingAnswerValue.splice(existingAnswerValue.findIndex(i => i.valueId === answerValue.valueId), 1)
                : existingAnswerValue.push(new AnswerValueNestedEditDto({
                    valueId: answerValue.id,
                    answerId: existingAnswer && (existingAnswer as AnswerNestedEditDto).id,
                }))
        }

        if (existingAnswer) {
            existingAnswer.answerValues = existingAnswerValue
        } else {
            answers.push(new AnswerNestedCreateDto({
                questionId: question.id,
                answerValues: existingAnswerValue,
            }))
        }

        this.unsavedDataDismissed = false;
    }

    setRadioButtonValue(question: QuestionDto, e: any): void {
        const el = (e.target as HTMLFormElement)
        const value = el.checked ? el.value : 'N/A'

        if (e.composed) {
            const answers = this.answers
            const existingAnswer = answers.find((answer: any) => answer.questionId === question.id)

            if (existingAnswer) {
                existingAnswer.content = value
            } else {
                answers.push(new AnswerNestedCreateDto({
                    questionId: question.id,
                    content: value,
                    answerValues: [],
                }));
            }
        }
    }

    setValueChecked(question: QuestionDto, valueId: number, isChecked: any): void {
        if(this.formFieldIsRadioButton(question)) {
            this.setRadioButtonValue(question, isChecked);
            return;
        }
        const answers = this.answers
        const existingAnswer = answers.find((answer: any) => answer.questionId === question.id)
        const existingAnswerValue = existingAnswer ? existingAnswer.answerValues || [] : []

        const valueAlreadyThere = existingAnswerValue.find((aval: any) => aval.valueId === valueId)

        if (isChecked && valueAlreadyThere === undefined) {
            existingAnswerValue.push(new AnswerValueNestedEditDto({
                valueId: valueId,
                answerId: existingAnswer && (existingAnswer as AnswerNestedEditDto).id,
            }))

            if (existingAnswer) {
                existingAnswer.answerValues = existingAnswerValue
            } else {
                answers.push(new AnswerNestedCreateDto({
                    questionId: question.id,
                    answerValues: existingAnswerValue,
                }))
            }
        } else if (!isChecked && valueAlreadyThere !== undefined) {
            existingAnswerValue.splice(existingAnswerValue.findIndex(i => i.valueId === valueId), 1)

            if (existingAnswer) {
                existingAnswer.answerValues = existingAnswerValue
            } else {
                answers.push(new AnswerNestedCreateDto({
                    questionId: question.id,
                    answerValues: existingAnswerValue,
                }))
            }
        }
    }

    // form fields
    formField(field: QuestionDto): string | undefined {
        return (field.questionType as any).name && formFieldMap[(field.questionType as any).name]
    }

    formFieldIsList(field: QuestionDto): boolean {
        return (field.values as any).length > 0
    }

    formFieldIsUserDropdown(field: QuestionDto): boolean {
        return (field.questionType as any).name == 'DropDownUsers'
    }

    formFieldIsRadioButton(field: QuestionDto): boolean {
        return (field.questionType as any).name == 'RadioButtonList';
    }

    private formFieldIsCheckbox(field: QuestionDto): boolean {
        return (field.questionType as any).name.indexOf('CheckboxList') > -1
    }

    // steps
    private isValid(): boolean {
        return this.steps.every(this.stepIsValid)
    }

    private stepIsValid(step: IScreenDto): boolean | undefined {
        return step.questions && step.questions.every(this.questionIsValid)
    }

    private questionIsValid(question: QuestionDto): boolean {
        if (question.isRequired) {
            const answer = this.getAnswer(question)
            const answerValue = this.getAnswerValue(question, undefined)

            if ((!!answer && answer.replace(/\s/g, '').length > 0) || !!answerValue) {
                return true
            }

            return false
        }

        return true
    }

    private firstInvalid(): string | undefined {
        const firstInvalidStep = this.steps.find(step => !this.stepIsValid(step))

        if (firstInvalidStep) {
            this.currentScreen = firstInvalidStep;
        }
        return (((firstInvalidStep || {}).questions || []).find(question => !this.questionIsValid(question)) || {}).text;
    }

    selectStep(step: IScreenDto): void {
        if (!this.submitted) {
            this.currentScreen = step
        }
    }

    async nextStep(): Promise<void> {
        if (this.lastStep) {
            await this.submit();
            if (this.validating) return;
        }

        const currentStepIndex = this.steps.indexOf(this.currentScreen)
        this.currentScreen = this.steps.indexOf(this.currentScreen) < this.steps.length - 1
            ? this.steps[currentStepIndex + 1]
            : this.currentScreen
    }

    async submit(id?: number): Promise<void> {
        if (!this.isValid()) {
            this.validating = true;

            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, this.firstInvalid()); // this.$t('fillAllFields'));
            return
        }
        this.validating = false;

        const ACTION = id
            ? actions.SUBMIT_IDEA
            : actions.SAVE_IDEA

        const r = await this.$store.dispatch(ACTION, {
            id,
            statusId: Status.Submitted,
            answers: this.answers,
        });

        if (r && r.id) {
            this.submitted = true;
        }
    }

    private gotoOverview(): void {
        this.$router.push({
            name: 'Home',
            params: {
                area: 'my',
            },
            query: {
                view: 'list',
            },
        });
    }

    async saveDraft(): Promise<void> {
        const r = await this.$store.dispatch(actions.SAVE_IDEA, {
            answers: this.answers,
        })

        r && r.id && this.$router.replace({
            name: 'Idea',
            params: {
                id: r.id,
            },
        })

        return r
    }

    private goBack(callback: Function): void {
        const defaultFn: () => void = () => this.$router.go(-1);
        const fn = callback || defaultFn;

        if (this.noUnsavedData || this.unsavedDataDismissed) {
            fn();
        } else {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, this.$t('unsavedData'));
            this.unsavedDataDismissed = true;
        }
    }

    private beforeRouteLeave(to: Route, from: Route, next: Function): void {
        from.name === 'Submit' && to.name === 'Idea'
        ||
        from.name === 'Idea' && to.name === 'IdeaEdit'
        ||
        from.name === 'Idea' && to.name === 'Home' && to.params.area === 'my' && to.query.view === 'list'
        ||
        from.name === 'Submit' && to.name === 'Home' && to.params.area === 'my' && to.query.view === 'list'
            ? next()
            : this.goBack(next);
    }


    downloadUserDropdownAnswer(question: QuestionDto): void {
        const downloadedAnswer = this.getUserDropdownAnswer(question)

        if (!downloadedAnswer) {
            const answers = this.answers
            const existingAnswer = answers.find((answer: any) => answer.questionId === question.id)

            if (existingAnswer && existingAnswer.content) {
                this.getUserValueForValue(existingAnswer.content)
            }
        }
    }

    private async getUserValueForValue(value: string): Promise<{label?: string; email?: string; secondaryLabel?: string; value?: string} | undefined> {
        for (const user of this.downloadedUsers) {
            if (user.secondaryLabel == value || user.label == value) {
                return user
            }
        }

        const userFilterModel: UserFilterModel = {
            page: 1,
            size: 1,
            term: value,
            userGroupId: null,
            sortColumn: 1,
            sortDirection: 'Ascending',
        };

        this.usersAreLoading = true

        const users = await new UserService().getAllPaged(userFilterModel);
        this.usersAreLoading = false

        if (users && users.result && users.result.items && users.result.items.length > 0) {
            const user = users.result.items[0]
            const userObject: {label: string; email: string; secondaryLabel: string; value: string} = {
                label: user.name || user.email || user.kid || '',
                email: user.email,
                secondaryLabel: user.kid || '',
                value: `${user.id}`,
            }

            this.downloadedUsers.push(userObject)
            this.downloadedUsers = [...new Set(this.downloadedUsers)]

            return userObject
        }

        return undefined
    }
}
