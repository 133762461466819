
















































































































import { getters } from '@/store/types';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { Status } from '@/service-proxies/service-proxies.g';
import RecipientsBusinessUnits from '../closure-report/recipients-business-units.vue';
import Permissions from '@/utils/helpers/permisssions';

@Component({
    name: 'delivery-specific-information',
    computed: mapGetters({
        ideaStatusId: getters.GET_IDEA_STATUS_ID,
    }),
    components: {
        RecipientsBusinessUnits
    }
})

export default class DeliverySpecificInformation extends Vue {
    @Prop({required: true, default: null })
    deliveryStrategyId!: number;

    @Prop({required: true, default: null })
    deliveryTypeId!: number;

    @Prop({required: true, default: null })
    deliveryOrganizationId!: number;

    @Prop({required: true, default: null })
    costCenterId!: number;

    @Prop({required: true, default: () => [] })
    serviceRecipientsBusinessUnits!: Array<any>;

    @Prop({required: true, default: null })
    workBreakdownStructure!: string;

    @Prop({ default: () => [] })
    deliveryStrategies!: Array<any>;

    @Prop({ default: () => []  })
    deliveryTypes!: Array<any>;

    @Prop({ default: () => []  })
    deliveryOrganizations!: Array<any>;

    @Prop({ default: () => []  })
    costCenters!: Array<any>;

    @Prop({required: true, default: false })
    invalidDeliveryOrganization!: boolean;

    @Prop({required: true, default: false })
    invalidDeliveryStrategy!: boolean;

    @Prop({required: true, default: false })
    invalidDeliveryType!: boolean;

    @Prop({required: true, default: false })
    invalidCostCenter!: boolean;

    @Prop({required: true, default: false})
    invalidBusinessUnits!: boolean;

    @Prop({required: true })
    editEnabled!: boolean;

    ideaStatusId!: number;

    get showWbsInput(): boolean {
        return this.ideaStatusId >= Status.DeliveryApproved;
    }

    get isWbsDisabled(): boolean {
        if(Permissions.isAdmin())
            return false;
        if(!Permissions.isProductOwnerOrProjectManager())
            return true;
        return this.ideaStatusId >= Status.InPoC;
    }
}
