interface FormFields {
    [index: string]: string;
}

const dropdown = 'pui-form-select'
const multiselectdropdown = 'pui-form-multi-select'

const formFields: FormFields = {
    'TextArea': 'pui-form-textarea',
    'CheckboxList': 'app-checkbox',
    'RadioButtonList': 'adam-radio',
    'DropDown': dropdown,
    'DropDownUsers': dropdown,
    'MultiselectDropDown': multiselectdropdown,

    'StakeHolderCustom': 'stake-holder-custom',
    'ScreenTotalCalculation': 'screen-total-calculation',
    'PhaseTotalCalculation': 'phase-total-calculation',
}

export default formFields
