












































import { Component, Prop, Vue } from 'vue-property-decorator';
import { CostsAndBenefitsValueItemDto } from '@/service-proxies/service-proxies.g';
import YearsHeader from '@/components/screen/common/years-header.vue';
import YearsInputs from '@/components/screen/common/years-inputs.vue';
import YearCalculations from './year-calculations';
import YearsDto from './years-dto';

@Component({
    name: 'value',
    components: {
        YearsHeader,
        YearsInputs,
    },
})

export default class Value extends Vue {
    @Prop({required: true, default: null })
    projectStartDate!: any;

    @Prop({required: true, default: null })
    yearsForecast!: number;

    @Prop({required: true, default: null })
    changeCost!: CostsAndBenefitsValueItemDto;

    @Prop({required: true, default: null })
    runCosts!: Array<CostsAndBenefitsValueItemDto>;

    @Prop({required: true, default: null })
    revenue!: CostsAndBenefitsValueItemDto;

    @Prop({required: true, default: null })
    opex!: CostsAndBenefitsValueItemDto;

    @Prop({required: true, default: null })
    capex!: CostsAndBenefitsValueItemDto;

    @Prop({required: true, default: null })
    itBenefits!: CostsAndBenefitsValueItemDto;

    @Prop({required: true })
    editEnabled!: boolean;

    @Prop({required: true, default: 0 })
    discountRate!: number;

    netValue: YearsDto = new YearsDto();
    netPresentValue= '';

    created(): void {
        this.calculateNetValue();
        this.calculateNetPresentValue();
    }

    public calculateNetValue(): void {
        const benefits = new YearsDto();
        YearCalculations.addYearValues(benefits, this.revenue);
        YearCalculations.addYearValues(benefits, this.opex);
        YearCalculations.addYearValues(benefits, this.capex);
        YearCalculations.addYearValues(benefits, this.itBenefits);

        const costs = new YearsDto();
        this.runCosts.forEach((runCost: any)  => {
            YearCalculations.addYearValues(costs, runCost);
        });
        YearCalculations.addYearValues(costs, this.changeCost);

        this.netValue = YearCalculations.substractYearValues(benefits, costs);
        this.netValue.comment = this.getTotalNetValue();
    }

    public calculateNetPresentValue(): void {
        const sumsPerYear = [this.netValue.year0, this.netValue.year1, this.netValue.year2, this.netValue.year3, this.netValue.year4];
        let npv = 0;
        const calculatedDiscountRate = 1 + this.discountRate/100;

        sumsPerYear.forEach(function (value, i) {
            npv+= Number(value)/Math.pow(calculatedDiscountRate,i);
        });

        this.netPresentValue = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(Number(npv));
    }

    private getTotalNetValue(): string {
        const total = YearCalculations.sumOfYearValues(this.netValue).toString();
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(Number(total));
    }
}
