


































































import CurrencyInput from '@/components/shared/currency-input.vue';
import { SelectOption } from '@/models';
import { IdeaServiceRecipientBusinessUnitDto, Status } from '@/service-proxies/service-proxies.g';
import { getters } from '@/store/types';
import { Component, Prop, Vue,} from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
    name: 'recipients-business-units',
    computed: mapGetters({
        recipientsBusinessUnits: getters.GET_SERVICE_RECIPIENTS_BUSINESS_UNITS,
        ideaStatusId: getters.GET_IDEA_STATUS_ID
    }),
    components: {
        CurrencyInput
    }
})
export default class RecipientsBusinessUnits extends Vue {

    @Prop({required: true })
    editEnabled!: boolean;

    @Prop({required: true, default: () => [] })
    businessUnits!: Array<IdeaServiceRecipientBusinessUnitDto>;

    @Prop({required: true, default: false})
    invalidBusinessUnits!: boolean;

    recipientsBusinessUnits!: Array<SelectOption>;
    ideaStatusId!: number;

    private maxAchievement = 100;

    private getRecipientsBusinessUnits(optionId: number): any[] {
        if(!this.businessUnits.length)
            return [];

        const usedIds = this.businessUnits.map((item: any) => item.serviceRecipientBusinessUnitId);
        return this.recipientsBusinessUnits.filter(
            (unit: SelectOption) => !usedIds.includes(unit.value) || unit.value === optionId
        );
    }

    get businessUnitsSum(): number {
        return this.convertToTwoDecimals(this.businessUnits.reduce(
            (partialSum, el) => partialSum + Number(el.invoicePercent), 0)
        );
    }

    get errorMessageForPercentage(): string {
        if(this.businessUnitsSum !== this.maxAchievement)
            return this.$t('closureReportForm.sumOfInvoiceRecipients') as string;
        else {
            return this.$t('closureReportForm.invoicePercentageIsRequired') as string;
        }
    }

    private getIsInvoicePercentValid(invoicePercentage?: number): boolean {
        if(this.ideaStatusId >= Status.Prioritized) {
            if(!invoicePercentage)
                return false;
            return invoicePercentage > 0 && this.businessUnitsSum === this.maxAchievement;
        }
        return true;
    }

    private getIsUnitValid(unitId?: number): boolean {
        if(this.ideaStatusId >= Status.Prioritized)
            return unitId !== undefined
        return true;
    }

    private getInvoicePercentWithDecimals(): number {
        const diff = this.maxAchievement - this.businessUnitsSum
        return diff > 0
            ? this.convertToTwoDecimals(diff)
            : 0;
    }

    private convertToTwoDecimals(value: number): number {
        return Number((Math.round(value * 100) / 100).toFixed(2))
    }

    addUnit(): void {
        this.businessUnits.push(
            new IdeaServiceRecipientBusinessUnitDto({
                invoicePercent: this.getInvoicePercentWithDecimals(),
                serviceRecipientBusinessUnitId: undefined
            }))
    }

    private handleInvoiceChange(value: any, index: number): void {
        this.businessUnits[index].invoicePercent = Number(value);
        this.$emit('invoiceValueChanged', value);
    }

    removeRow(index: number): void {
        this.businessUnits.splice(index, 1);
    }
}
