


















































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Years from '@/components/screen/common/years';
import Currency from '@/utils/helpers/currency';

interface InputValues {
    year0: number;
    year1: number;
    year2: number;
    year3: number;
    year4: number;
    comment: string;
}

@Component({
    name: 'years-inputs',
})
export default class CommentAndYearsInputs extends Vue{
    @Prop({default: () => []})
    readonly selectBoxOptions!: Array<any>;

    @Prop({default: true, required: false})
    readonly isRequired!: boolean;

    @Prop({required: false })
    readonly selectBoxPlaceholder!: string;

    @Prop({required: false })
    readonly inputText!: string;

    @Prop({required: false, default: null })
    readonly selectBoxValue!: number;

    @Prop()
    inputValues!: InputValues;

    @Prop({required: true, default: 5 })
    readonly yearsForecast!: number;

    @Prop({required: false, default: false })
    readonly isReadOnly!: boolean;

    @Prop({required: false, default: false })
    showErrorsIfExists!: boolean;

    @Prop({default: false })
    hideCharacterCount!: boolean;

    @Prop({required: false })
    runCostIndex!: number;

    @Prop({required: false, default: false })
    showDeleteButton!: boolean;

    @Prop({required: true })
    editEnabled!: boolean;

    years = Years;
    commentCharacterLimit = 500;

    currencyToStringNumber = (value: any): string => {
        if (!this.editEnabled) return value;
        const formattedValue = Currency.currencyToStringNumber(value);
        if (Number(formattedValue) === 0) return '';
        return formattedValue;
    };
    currencyToNumber = (currencyValue: any): any => Currency.currencyToNumber(currencyValue);
    numberToCurrency = (value: any): string | null => Currency.numberToCurrency(value);

    get selectBoxOptionsNotEmpty(): boolean {
        return this.selectBoxOptions && this.selectBoxOptions.length > 0;
    }

    get showCommentSecondRow(): boolean {
        return this.selectBoxOptionsNotEmpty
            && this.inputValues != null;
    }

    year0: string = this.numberToCurrency(this.inputValues?.year0) ?? '0.00';
    year1: string = this.numberToCurrency(this.inputValues?.year1) ?? '0.00';
    year2: string = this.numberToCurrency(this.inputValues?.year2) ?? '0.00';
    year3: string = this.numberToCurrency(this.inputValues?.year3) ?? '0.00';
    year4: string = this.numberToCurrency(this.inputValues?.year4) ?? '0.00';

    @Watch('inputValues.year0')
    year0Changed(newValue: any): void {
        this.year0 = this.currencyToNumber(newValue);
        this.inputValues.year0 = this.currencyToNumber(newValue);
    }

    @Watch('inputValues.year1')
    year1Changed(newValue: any): void {
        this.year1 = this.currencyToNumber(newValue);
        this.inputValues.year1 = this.currencyToNumber(newValue);
    }

    @Watch('inputValues.year2')
    year2Changed(newValue: any): void {
        this.year2 = this.currencyToNumber(newValue);
        this.inputValues.year2 = this.currencyToNumber(newValue);
    }

    @Watch('inputValues.year3')
    year3Changed(newValue: any): void {
        this.year3 = this.currencyToNumber(newValue);
        this.inputValues.year3 = this.currencyToNumber(newValue);
    }

    @Watch('inputValues.year4')
    year4Changed(newValue: string): void {
        this.year4 = this.currencyToNumber(newValue);
        this.inputValues.year4 = this.currencyToNumber(newValue);
    }

    onKeyUp(e: any): void {
        const intCurrencyValue = e.target.value.split('.')[0];
        const onlyDigitsValue = intCurrencyValue.replace(/\D/g, '');
        if (onlyDigitsValue.length < e.target.value.length) {
            if (Number(onlyDigitsValue) == 0) {
                e.target.value = '';
            } else {
                e.target.value = onlyDigitsValue;
            }
        }
    }

    selectBoxInvalid(value: number | null): boolean {
        const invalid = (this.showErrorsIfExists || this.pleaseSelectOption) && value == null;
        this.$root.$emit('invalidCostAndBenefitTab', invalid);
        return invalid;
    }

    get pleaseSelectOption(): boolean {
        return (!!this.inputValues[Years.year0]
            || !!this.inputValues[Years.year1]
            || !!this.inputValues[Years.year2]
            || !!this.inputValues[Years.year3]
            || !!this.inputValues[Years.year4]
            || !!this.inputValues.comment)
    }

    get characterCountDisplay(): string {
        if (this.hideCharacterCount)
            return '';

        const commentLength = this.inputValues.comment?.length
            ? this.inputValues.comment.length
            : 0;
        return `${commentLength }/${this.commentCharacterLimit}`;
    }
}
