






































































































































import { SelectOption } from '@/models';
import { DeliveryStatus } from '@/models/delivery-status';
import { CostsAndBenefitsValueItemDto, CostsAndBenefitsValueItemRunCostDto } from '@/service-proxies/service-proxies.g';
import { getters } from '@/store/types';
import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import YearsHeader from '../common/years-header.vue';
import YearsInputs from '../common/years-inputs.vue';

const DATE_FORMAT = 'YYYY-MM-DD';

const STATUS_COLORS = {
    ON_TRACK: '#00E676',
    RISK_TO_DELIVERY: '#F50057',
    ROADBLOCKS: '#FF6F00',
    NO_STATUS: '#ADADAD'
}

const DELIVERY_STATUSES = {
    ON_TRACK: 1,
    NO_STATUS: 2,
    ROADBLOCKS: 3,
    RISK_TO_DELIVERY: 4
}

@Component({
    name: 'delivery-information',
    computed: mapGetters({
        deliveryStatuses: getters.GET_DELIVERY_STATUSES,
        idea: getters.GET_IDEA,
    }),
    components: {
        YearsHeader,
        YearsInputs,
    }
})

export default class DeliveryInformation extends Vue {

    deliveryStatuses!: DeliveryStatus[];
    idea!: any;

    @Prop({required: true, default: () => new CostsAndBenefitsValueItemDto()})
    newChangeCosts!: CostsAndBenefitsValueItemDto;

    @Prop({required: true, default: () => [] })
    runCosts!: Array<CostsAndBenefitsValueItemRunCostDto>;

    @Prop({ default: () => [] })
    costTypes!: Array<any>;

    @Prop({required: true })
    editEnabled!: boolean;

    commentCharacterLimit = 500;

    addRunCost(): void {
        this.runCosts.push(
            new CostsAndBenefitsValueItemRunCostDto({
                comment: undefined,
                year0: undefined,
                year1: undefined,
                year2: undefined,
                year3: undefined,
                year4: undefined,
                year5: undefined,
                costTypeId: undefined,
            }))
    }

    removeRow(index: number): void {
        this.runCosts.splice(index, 1);
    }

    private handleDateChanged(date: any): void {
        this.$emit('deliveryEndDateChanged', date)
    }

    private get deliveryStatusesOptions(): SelectOption[] {
        return this.deliveryStatuses.map((status: DeliveryStatus) => ({
            label: status.statusName,
            value: status.id
        }))
    }

    private get deliveryStatusId(): number {
        return this.idea.deliveryStatusId ?? DELIVERY_STATUSES.NO_STATUS;
    }

    private get deliveryEndDate(): any {
        const date = this.idea.newDeliveryEndDate ?? this.idea.costsAndBenefits?.projectEndDate;
        return this.formatDate(date);
    }

    private get deliveryEndDateComment(): string {
        return this.idea.newDeliveryEndDateComment;
    }

    get characterCountDisplay(): string {
        const commentLength = this.deliveryEndDateComment?.length
            ? this.deliveryEndDateComment.length
            : 0;
        return `${commentLength }/${this.commentCharacterLimit}`;
    }

    get statusIconColor(): string {
        switch(this.deliveryStatusId){
        case DELIVERY_STATUSES.ON_TRACK:
            return STATUS_COLORS.ON_TRACK;
        case DELIVERY_STATUSES.NO_STATUS:
            return STATUS_COLORS.NO_STATUS;
        case DELIVERY_STATUSES.ROADBLOCKS:
            return STATUS_COLORS.ROADBLOCKS;
        case DELIVERY_STATUSES.RISK_TO_DELIVERY:
            return STATUS_COLORS.RISK_TO_DELIVERY;
        default:
            return STATUS_COLORS.NO_STATUS;
        }
    }

    formatDate(date: any): string {
        return moment(date).format(DATE_FORMAT);
    }
}
