


























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
    CostsAndBenefitsValueItemDto, CostsAndBenefitsValueItemRunCostDto
} from '@/service-proxies/service-proxies.g';
import YearsHeader from '@/components/screen/common/years-header.vue';
import YearsInputs from '@/components/screen/common/years-inputs.vue';

@Component({
    name: 'development-and-implementation-cost',
    components: {
        YearsHeader,
        YearsInputs,
    },
})

export default class DevelopmentAndImplementationCost extends Vue {
    @Prop({required: true, default: null })
    projectStartDate!: any;

    @Prop({required: true, default: null })
    yearsForecast!: number;

    @Prop({required: true, default: null })
    changeCost!: CostsAndBenefitsValueItemDto;

    @Prop({required: true, default: [] })
    runCosts!: Array<CostsAndBenefitsValueItemRunCostDto>;

    @Prop({required: true, default: false })
    runCostAgreed!: boolean;

    @Prop({ default: () => [] })
    costTypes!: Array<any>;

    @Prop({required: true, default: false })
    showErrorsIfExists!: boolean;

    @Prop({required: true })
    editEnabled!: boolean;

    @Prop({required: true, default: true })
    invalidDevelopmentAndImplementationCost!: boolean;

    @Watch('changeCost', { deep: true })
    changeCostChanged(changeCost: any): void {
        this.$emit('changeCostChange', changeCost);
    }

    @Watch('runCosts', { deep: true })
    runCostsChanged(runCosts: any): void {
        this.$emit('runCostsChange', runCosts);
    }

    addRunCost(): void {
        this.runCosts.push(
            new CostsAndBenefitsValueItemRunCostDto({
                comment: undefined,
                year0: undefined,
                year1: undefined,
                year2: undefined,
                year3: undefined,
                year4: undefined,
                year5: undefined,
                costTypeId: undefined,
            }))
    }

    emptyCostsAndBenefitsValueItemRunCostDto(): CostsAndBenefitsValueItemRunCostDto {
        return new CostsAndBenefitsValueItemRunCostDto({
            comment: undefined,
            year0: undefined,
            year1: undefined,
            year2: undefined,
            year3: undefined,
            year4: undefined,
            year5: undefined,
            costTypeId: undefined,
        });
    }

    get invalidRunCostsAgreed(): boolean {
        return this.showErrorsIfExists && !this.runCostAgreed;
    }

    removeRow(index: number): void {
        this.runCosts.splice(index, 1);
    }
}
