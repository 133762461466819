var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-card"},[_c('div',{staticClass:"card-header"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('closureReportForm.actualCosts'))+" ")])]),(Object.keys(_vm.changeCosts).length)?_c('pui-table',{staticClass:"changeCostTable",attrs:{"vendor-options":_vm.changeCostTableConfig},scopedSlots:_vm._u([{key:_vm.changeCostTableColumnHeaders.empty,fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex-row flex-center bold"},[_vm._v(" "+_vm._s(row.empty)+" ")])]}},{key:_vm.getYear(0),fn:function(ref){
var row = ref.row;
return [_c('pui-form-input-field',{directives:[{name:"pui-form-grid-column",rawName:"v-pui-form-grid-column",value:(_vm.columnSize),expression:"columnSize"}],attrs:{"type":"number","value":_vm.getInputValue(row.year0),"is-disabled":_vm.isReadonly,"is-readonly":_vm.isReadonly},on:{"input":function (value) { return _vm.actualCosts.changeCosts.year0 = Number(value); },"blur":function($event){return _vm.getChangeCostDefaultValue('year0')}}})]}},{key:_vm.getYear(1),fn:function(ref){
var row = ref.row;
return [_c('pui-form-input-field',{directives:[{name:"pui-form-grid-column",rawName:"v-pui-form-grid-column",value:(_vm.columnSize),expression:"columnSize"}],attrs:{"type":"number","value":_vm.getInputValue(row.year1),"is-disabled":_vm.isReadonly,"is-readonly":_vm.isReadonly},on:{"input":function (value) { return _vm.actualCosts.changeCosts.year1 = Number(value); },"blur":function($event){return _vm.getChangeCostDefaultValue('year1')}}})]}},{key:_vm.getYear(2),fn:function(ref){
var row = ref.row;
return [_c('pui-form-input-field',{directives:[{name:"pui-form-grid-column",rawName:"v-pui-form-grid-column",value:(_vm.columnSize),expression:"columnSize"}],attrs:{"type":"number","value":_vm.getInputValue(row.year2),"is-disabled":_vm.isReadonly,"is-readonly":_vm.isReadonly},on:{"input":function (value) { return _vm.actualCosts.changeCosts.year2 = Number(value); },"blur":function($event){return _vm.getChangeCostDefaultValue('year2')}}})]}},{key:_vm.getYear(3),fn:function(ref){
var row = ref.row;
return [_c('pui-form-input-field',{directives:[{name:"pui-form-grid-column",rawName:"v-pui-form-grid-column",value:(_vm.columnSize),expression:"columnSize"}],attrs:{"type":"number","value":_vm.getInputValue(row.year3),"is-disabled":_vm.isReadonly,"is-readonly":_vm.isReadonly},on:{"input":function (value) { return _vm.actualCosts.changeCosts.year3 = Number(value); },"blur":function($event){return _vm.getChangeCostDefaultValue('year3')}}})]}},{key:_vm.getYear(4),fn:function(ref){
var row = ref.row;
return [_c('pui-form-input-field',{directives:[{name:"pui-form-grid-column",rawName:"v-pui-form-grid-column",value:(_vm.columnSize),expression:"columnSize"}],attrs:{"type":"number","value":_vm.getInputValue(row.year4),"is-disabled":_vm.isReadonly,"is-readonly":_vm.isReadonly},on:{"input":function (value) { return _vm.actualCosts.changeCosts.year4 = Number(value); },"blur":function($event){return _vm.getChangeCostDefaultValue('year4')}}})]}},{key:_vm.commonColumnHeader.total,fn:function(ref){
var row = ref.row;
return [_c('pui-form-input-field',{directives:[{name:"pui-form-grid-column",rawName:"v-pui-form-grid-column",value:(_vm.columnSize),expression:"columnSize"}],attrs:{"is-disabled":true,"is-readonly":true,"value":_vm.getInputValue(row.total)}})]}}],null,true)},[_c('template',{slot:_vm.runCostTableColumnHeaders.costTypeId},[_c('div',{staticClass:"flex-row flex-center bold"},[_vm._v(" "+_vm._s(_vm.getLongestCostTypeName())+" ")])])],2):_vm._e(),(_vm.runCosts && _vm.runCosts.length)?_c('pui-table',{attrs:{"vendor-options":_vm.runCostTableConfig},scopedSlots:_vm._u([{key:_vm.runCostTableColumnHeaders.empty,fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex-row flex-center bold"},[_vm._v(" "+_vm._s(row.empty)+" ")])]}},{key:_vm.runCostTableColumnHeaders.costTypeId,fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex-row flex-center bold"},[_vm._v(" "+_vm._s(_vm.getCostTypeName(row.costTypeId))+" ")])]}},{key:_vm.getYear(0),fn:function(ref){
var row = ref.row;
return [_c('pui-form-input-field',{directives:[{name:"pui-form-grid-column",rawName:"v-pui-form-grid-column",value:(_vm.columnSize),expression:"columnSize"}],attrs:{"type":"number","value":_vm.getInputValue(row.year0),"is-disabled":_vm.isReadonly,"is-readonly":_vm.isReadonly},on:{"input":function (value) { return _vm.actualCosts.runCosts[row.index].year0 = Number(value); },"blur":function($event){return _vm.getRunCostDefaultValue(row.index, 'year0')}}})]}},{key:_vm.getYear(1),fn:function(ref){
var row = ref.row;
return [_c('pui-form-input-field',{directives:[{name:"pui-form-grid-column",rawName:"v-pui-form-grid-column",value:(_vm.columnSize),expression:"columnSize"}],attrs:{"type":"number","value":_vm.getInputValue(row.year1),"is-disabled":_vm.isReadonly,"is-readonly":_vm.isReadonly},on:{"input":function (value) { return _vm.actualCosts.runCosts[row.index].year1 = Number(value); },"blur":function($event){return _vm.getRunCostDefaultValue(row.index, 'year1')}}})]}},{key:_vm.getYear(2),fn:function(ref){
var row = ref.row;
return [_c('pui-form-input-field',{directives:[{name:"pui-form-grid-column",rawName:"v-pui-form-grid-column",value:(_vm.columnSize),expression:"columnSize"}],attrs:{"type":"number","value":_vm.getInputValue(row.year2),"is-disabled":_vm.isReadonly,"is-readonly":_vm.isReadonly},on:{"input":function (value) { return _vm.actualCosts.runCosts[row.index].year2 = Number(value); },"blur":function($event){return _vm.getRunCostDefaultValue(row.index, 'year2')}}})]}},{key:_vm.getYear(3),fn:function(ref){
var row = ref.row;
return [_c('pui-form-input-field',{directives:[{name:"pui-form-grid-column",rawName:"v-pui-form-grid-column",value:(_vm.columnSize),expression:"columnSize"}],attrs:{"type":"number","value":_vm.getInputValue(row.year3),"is-disabled":_vm.isReadonly,"is-readonly":_vm.isReadonly},on:{"input":function (value) { return _vm.actualCosts.runCosts[row.index].year3 = Number(value); },"blur":function($event){return _vm.getRunCostDefaultValue(row.index, 'year3')}}})]}},{key:_vm.getYear(4),fn:function(ref){
var row = ref.row;
return [_c('pui-form-input-field',{directives:[{name:"pui-form-grid-column",rawName:"v-pui-form-grid-column",value:(_vm.columnSize),expression:"columnSize"}],attrs:{"type":"number","value":_vm.getInputValue(row.year4),"is-disabled":_vm.isReadonly,"is-readonly":_vm.isReadonly},on:{"input":function (value) { return _vm.actualCosts.runCosts[row.index].year4 = Number(value); },"blur":function($event){return _vm.getRunCostDefaultValue(row.index, 'year4')}}})]}},{key:_vm.commonColumnHeader.total,fn:function(ref){
var row = ref.row;
return [_c('pui-form-input-field',{directives:[{name:"pui-form-grid-column",rawName:"v-pui-form-grid-column",value:(_vm.columnSize),expression:"columnSize"}],attrs:{"is-disabled":true,"is-readonly":true,"value":_vm.getInputValue(row.total)}})]}}],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }