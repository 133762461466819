import domtoimage from 'dom-to-image';

export default class ImageConverter {
    public async downloadImage(canvasElement: HTMLElement, fileName: string): Promise<void> {
        const clone = canvasElement.cloneNode(true) as HTMLElement;
        const scale = 3;

        const wrapper = document.createElement('div');
        wrapper.classList.add('canvas-scale');
        clone.style.cssText = 'font-size: 300%; width: 350mm; background: #fff';
        wrapper.style.cssText = 'width: 0; height: 0; overflow: hidden';
        const el = wrapper.appendChild(clone);

        document.body.appendChild(wrapper);
        const dataurl = await domtoimage.toPng(el, {
            width: el.clientWidth * scale,
            height: el.clientHeight * scale * 1.5,
            style: {
                transform: 'scale(' + scale + ')',
                transformOrigin: 'top left',
            },
            quality: 0.99,
        });
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.download = `${fileName}.png`;
        link.href = dataurl;
        link.click();

        return dataurl;
    }
}
