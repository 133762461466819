
























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import ProjectManagerSelector from './project-manager-selector.vue'
import moment from 'moment';
import { mapGetters } from 'vuex';
import { getters } from '@/store/types';

const datePickerFormat = 'YYYY-MM-DD';

@Component({
    name: 'general',
    components: {
        ProjectManagerSelector,
    },
    computed: mapGetters({
        ideaStatusId: getters.GET_IDEA_STATUS_ID,
    }),
})
export default class General extends Vue {
    @Prop({required: true, default: null })
    projectManagerId!: number;

    @Prop({required: true, default: null })
    projectStartDate!: any;

    @Prop({required: true, default: null })
    projectEndDate!: any;

    @Prop({required: true, default: [] })
    strategicThemes!: Array<any>;

    @Prop({required: true, default: null })
    strategicThemeId!: number;

    @Prop({required: true, default: false })
    invalidStrategicTheme!: boolean;

    @Prop({required: true, default: false })
    invalidProjectManager!: boolean;

    @Prop({required: true, default: null })
    startDateErrorMessage!: string;

    @Prop({required: true, default: null })
    endDateErrorMessage!: string;

    @Prop({required: true })
    editEnabled!: boolean;

    ideaStatusId!: number;

    formatDate(date: any): string {
        return moment(date).format(datePickerFormat);
    }
}


