












































































import { SelectOption, ServiceEnablementContact } from '@/models';
import { Status } from '@/service-proxies/service-proxies.g';
import { getters } from '@/store/types';
import Permissions from '@/utils/helpers/permisssions';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
    name: 'service-enablement',
    computed: mapGetters({
        enablementContacts: getters.GET_ENABLEMENT_CONTACTS,
        ideaStatusId: getters.GET_IDEA_STATUS_ID
    }),
})

export default class ServiceEnablement extends Vue {

    enablementContacts!: ServiceEnablementContact[];
    ideaStatusId!: number;

    @Prop({required: true})
    private serviceEnablementContact!: number;

    @Prop({required: true})
    private serviceEnablementContactComment!: string;

    @Prop({required: true, default: false})
    private serviceEnablementRequired!: boolean;

    @Prop({required: true })
    editEnabled!: boolean;

    @Prop({required: true })
    invalidServiceEnablementContact!: boolean;

    private enablementRequired = this.serviceEnablementRequired as boolean;

    private handleCoordinatorChange(coordinatorId: number): void {
        this.$emit('enablementContactChange', coordinatorId);
        this.$emit('enablementContactCommentChange', null);
    }

    private handleEnablementRequiredChange(): void {
        this.enablementRequired = !this.enablementRequired;
        this.$emit('enablementRequiredChange', this.enablementRequired)

        if(!this.enablementRequired) {
            this.resetValuesWhenNotRequired();
        }
    }

    private resetValuesWhenNotRequired(): void {
        this.$emit('enablementContactChange', null);
        this.$emit('enablementContactCommentChange', null);
    }

    private get serviceEnablementContactsList(): SelectOption[] {
        return this.enablementContacts.map((contact: ServiceEnablementContact) => (
            {
                label: contact?.name,
                value: contact?.id
            })
        );
    }

    private getSelectedCoordinatorKid(): string {
        const selectedCoordinator = this.enablementContacts.filter(
            (contact: ServiceEnablementContact) =>
                contact.id === this.serviceEnablementContact
        )[0];
        return selectedCoordinator && selectedCoordinator.kid ?  selectedCoordinator.kid : '';
    }

    private get userIsSelectedCoordinator(): boolean {
        return Permissions.loggedInKidIs(this.getSelectedCoordinatorKid()) || Permissions.isAdmin();
    }

    private get isReadonly(): boolean {
        return !Permissions.isAdmin() && !this.editEnabled;
    }

    private get isRequired(): boolean {
        return this.ideaStatusId >= Status.Prioritized;
    }
}
