
























import { Component, Prop, Vue,} from 'vue-property-decorator';

@Component({
    name: 'confirmation-screen',
})
export default class ConfirmationScreen extends Vue {
    @Prop({default: ''})
    message!: string;

    @Prop({default: ''})
    confirmButtonLabel!: string;

    @Prop({default: true})
    hasButton!: boolean;

    private handleClick(): void {
        this.$emit('confirm');
    }
}
