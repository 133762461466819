

import Submit from '../submit/submit.vue'
import { Component, Watch } from 'vue-property-decorator';

import { Status, IAnswerDto, AnswerValueNestedEditDto } from '@/service-proxies/service-proxies.g';
import { actions, mutations } from '@/store/types';
import { i18n } from '@/i18n-instance-file';
import MessageHandler from '@/utils/message-handler';
import { ToastMessageTypes } from '@/mixins/toast-message/toast-message-types';

@Component({
    name: 'idea-edit',
})
export default class Idea extends Submit {

    private id = this.$route.params.id;

    @Watch('$route.params.id')
    onIdChanged(to: any): void {
        this.getData(to);
    }

    private gotoScreen(idea: any): void {
        idea.statusId > Status.Draft
            ? this.$router.replace({
                name: 'IdeaEdit',
                params: {
                    id: idea.id
                }
            })
            : void 0
    }

    public async getData(id = this.id): Promise<void> {
        //
        function cleanAnswerValues(answer: any): any {
            const answerValues = (answer.answerValues || []).map((v: any) => new AnswerValueNestedEditDto({
                valueId: v.valueId,
                id: v.id,
                answerId: answer.id,
            })).filter((v: any) => v.valueId)

            return answerValues.length ? answerValues : [...answerValues]
        }

        if (this.$store.state.ideas.idea.id !== +id) {
            await (Submit as any).extendOptions.methods.getData.call(this);
            await this.$store.dispatch(actions.LOAD_IDEA, id);
        }

        this.answers = (this.$store.state.ideas.idea.answers || [])
            .map((answer: IAnswerDto) => ({
                questionId: answer.questionId,
                content: answer.content,
                answerValues: cleanAnswerValues(answer),
                id: answer.id,
                ideaId: answer.ideaId,
            }));

        this.gotoScreen(this.$store.state.ideas.idea);
        this.initialAnswers = JSON.stringify(this.answers);
    }

    public async saveDraft(id = this.id, answers = this.answers, newStatus = Status.Draft): Promise<void> {
        const r = await this.$store.dispatch(actions.EDIT_IDEA, {
            id,
            data: {
                id: +id,
                answers,
                newStatus,
            }
        })
        if (r) {
            MessageHandler.showToastMessage(i18n.t('successfullySaved') as string, ToastMessageTypes.SUCCESS);
            this.$store.commit(mutations.SET_IDEA, r);
        }
        this.getData();
    }

    public async submit(id: number = +this.$route.params.id): Promise<void> {
        await (Submit as any).extendOptions.methods.submit.call(this, +id);
    }
}

