import { render, staticRenderFns } from "./years-header.vue?vue&type=template&id=2ad05c3c&scoped=true&"
import script from "./years-header.vue?vue&type=script&lang=ts&"
export * from "./years-header.vue?vue&type=script&lang=ts&"
import style0 from "./years-header.vue?vue&type=style&index=0&id=2ad05c3c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ad05c3c",
  null
  
)

export default component.exports