












































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { CostsAndBenefitsValueItemBenefitDto } from '@/service-proxies/service-proxies.g';
import { mapGetters } from 'vuex';
import { getters } from '@/store/types';
import { SelectOption } from '@/models';

@Component({
    name: 'indirect-benefits',
    computed: mapGetters({
        benefitTypes: getters.GET_BENEFIT_TYPES,
    }),
})

export default class IndirectBenefits extends Vue {
    @Prop({required: true, default: null })
    indirectBenefits!: Array<CostsAndBenefitsValueItemBenefitDto>;

    @Prop({required: true, default: false })
    showErrorsIfExists!: boolean;

    @Prop({required: true })
    editEnabled!: boolean;

    benefitTypes!: Array<SelectOption>;

    commentCharacterLimit = 500;

    addBenefit(): void {
        this.indirectBenefits.push(
            new CostsAndBenefitsValueItemBenefitDto({
                comment: undefined,
                benefitTypeId: undefined,
            }))
    }

    selectBoxInvalid(value: number | null, indirectBenefit: any): boolean {
        return value == null
            && (this.showErrorsIfExists
                || (!indirectBenefit.benefitTypeId && !!indirectBenefit.comment));
    }

    characterCountDisplay(comment: string | undefined): string {
        const commentLength = comment?.length ? comment.length : 0;
        return `${commentLength }/${this.commentCharacterLimit}`;
    }

    removeRow(index: number): void {
        this.indirectBenefits.splice(index, 1);
    }
}
