






















































































































































































import { Component, Watch, Vue } from 'vue-property-decorator';
import { EventBus } from '@/utils';
import {
    Status,
    IScreenDto,
    QuestionDto,
    IAnswerDto, IAnswerNestedEditDto, AnswerValueNestedEditDto,
    IIdeaCommentNestedEditDto,
} from '@/service-proxies/service-proxies.g';

import { UserService } from '@/services/user-service';
import { IdeaForm, UserFilterModel } from '@/models';

import Screen, { ScreenNames } from '@/components/screen/screen.vue';
import CostsAndBenefits from '@/components/screen/costs-and-benefits/index.vue';
import RealizedAndScaledBenefits from '@/components/screen/realized-and-scaled-benefits/index.vue'
import OnePager from '@/components/widgets/one-pager.vue';
import Comments from '@/components/widgets/comments.vue';

import PermissionNames from '@/utils/permission-names';
import { actions, getters, mutations } from '@/store/types';
import { mapGetters, mapActions } from 'vuex';
import ClearObject from '@/utils/helpers/clear-object';
import Permissions from '@/utils/helpers/permisssions';
import CostsAndBenefitsTemplate from '@/components/screen/costs-and-benefits/costs-and-benefits-template';
import BenefitsTemplate from '@/components/screen/realized-and-scaled-benefits/benefits-template';
import { Route } from 'vue-router';
import RelatedIdeasList from '@/components/widgets/related-ideas-list.vue';
import { RelatedIdea } from '@/models/related-idea';
import AnswersHelper from '@/utils/helpers/answers-helper';
import UnsavedChangesModal from './unsaved-changes-modal.vue';
import MessageHandler from '@/utils/message-handler';
import { ToastMessageTypes } from '@/mixins/toast-message/toast-message-types';

Component.registerHooks([
    'beforeRouteLeave',
]);

interface ScreenDtoWithScore extends IScreenDto {
    scores?: any[];
}

interface ScreenGroup extends IScreenDto {
    group?: IScreenDto[];
}

const notAQuestion = [1, 2]

@Component({
    name: 'idea-review',
    components: {
        Screen,
        OnePager,
        Comments,
        CostsAndBenefits,
        RealizedAndScaledBenefits,
        RelatedIdeasList,
        UnsavedChangesModal
    },
    computed: mapGetters({
        idea: getters.GET_IDEA,
        costsAndBenefits: getters.GET_IDEA_COSTS_AND_BENEFITS,
        realizedAndScaledBenefits: getters.GET_IDEA_REALIZED_AND_SCALED_BENEFITS,
    }),
    methods: {
        ...mapActions({
            loadBenefitTypes: actions.LOAD_BENEFIT_TYPES,
            loadServiceRecipientsBusinessUnits: actions.LOAD_SERVICE_RECIPIENTS_BUSINESS_UNITS,
            loadRelatedIdeas: actions.LOAD_RELATED_IDEAS,
            loadServiceEnablementContacts: actions.LOAD_ENABLEMENT_CONTACTS,
            loadDeliveryStatuses: actions.LOAD_DELIVERY_STATUSES,
            setCostsAndBenefitsReadOnly: actions.EDIT_COSTS_AND_BENEFITS_READ_ONLY
        })
    }
})
export default class IdeaEdit extends Vue {
    $store: any;
    idea!: any;
    costsAndBenefits!: any;
    realizedAndScaledBenefits!: any;
    loadBenefitTypes!: () => Promise<void>;
    loadServiceRecipientsBusinessUnits!: () => Promise<void>;
    loadRelatedIdeas!: (id: string) => RelatedIdea[];
    loadServiceEnablementContacts!: () => void;
    loadDeliveryStatuses!: () => Promise<void>;
    setCostsAndBenefitsReadOnly!: (readOnly: boolean) => void;

    costsAndBenefitsScreenNameKey = 'costsAndBenefits.costsAndBenefitsTabName';
    realizedBenefitsScreenNameKey = 'realizedAndScaledBenefits.realizedBenefitsTabName';
    private initialIdea = '';
    private markAnswersAsInvalid = false;

    async mounted(): Promise<void> {
        this.setCostsAndBenefitsReadOnly(this.ideaStatusId >= Status.DeliveryApproved);
        this.$root.$on('invalidCostAndBenefitTab', (invalid: boolean) => {
            this.invalidCostAndBenefitTab = invalid;
        });
        this.$root.$on('valueOfCostsAndBenefitsChanged',(hasChanged: boolean) => {
            this.changesHaveBeenMade = hasChanged;
        });
        this.$root.$on('ideaChanged',() => {
            this.handleIdeaChanged();
        })
        this.loadBenefitTypes();
        this.loadServiceRecipientsBusinessUnits();
        this.loadServiceEnablementContacts();
        this.loadDeliveryStatuses();
    }

    private beforeDestroy(): void {
        EventBus.$off('invalidCostAndBenefitTab');
        EventBus.$off('valueOfCostsAndBenefitsChanged');
    }

    private beforeRouteLeave(to: Route, from: Route, next: Function): void {
        if(this.changesHaveBeenMade){
            const answer = window.confirm(this.$t('unsavedDataConfirmationMessage').toString());
            if (answer) {
                next();
            } else {
                next(false);
            }
        }
        else
            next();
    }

    isCostsAndBenefitsTabInvalid(tab: any): boolean {
        return this.invalidCostAndBenefitTab
            && tab.name === this.$t(this.costsAndBenefitsScreenNameKey);
    }

    private userFilterModel: UserFilterModel = {
        page: 1,
        size: 300,
        term: '',
        userGroupId: null,
        sortColumn: 1,
        sortDirection: 'Ascending',
    };

    private callToActionOptions: Array<any> = [
        { status: Status.Submitted, translateKey: 'finishEvaluation'},
        { status: Status.Evaluated, translateKey: 'finishPrioritization'},
        { status: Status.Prioritized, translateKey: 'confirmDelivery'},
        { status: Status.DeliveryApproved, translateKey: 'moveToImplementation'},
        { status: Status.InPoC, translateKey: 'moveToImplementation'},
        { status: Status.InMVP, translateKey: 'moveToImplementation'},
        { status: Status.InMP, translateKey: 'moveToImplementation'},
        { status: Status.InImplementation, translateKey: 'moveToInUse'},
    ];

    private ScreenNames = ScreenNames;
    private editMode = false;
    invalidCostAndBenefitTab = false;
    changesHaveBeenMade = false;

    private hasPermission(permission: string): boolean {
        if (this.$store.state.user.permissions) {
            return !!~this.$store.state.user.permissions.indexOf(permission);
        }

        return false
    }

    private get showMoveToNextStatusButton(): boolean {
        return !this.shouldShowClosureReportButton &&
        !this.isClosureReportAvailable ||
        this.ideaIsBelowInUseStatus;
    }

    private get ideaIsBelowInUseStatus(): boolean {
        return this.$store.state.ideas.idea.statusId < 12;
    }

    private get isClosureReportAvailable(): boolean {
        return this.$store.state.ideas.idea.hasClosureReport;
    }

    private get canEditAnswers(): boolean {
        return this.hasPermission(PermissionNames.EditIdea)
    }

    private get canEditStatus(): boolean {
        return this.hasPermission(PermissionNames.UpdateStatus);
    }

    private get canApprove(): boolean {
        if (!this.$store.state.ideas.idea?.actions?.length) return false
        return Permissions.canUpdateIdeaStatus();
    }

    private get initiatorIsCurrentUser(): boolean {
        if (this.$store.state.user.user && this.$store.state.ideas.idea) {
            const currentUserEmail = this.$store.state.user.user.email
            const createdByEmail = this.$store.state.ideas.idea.createdByEmail

            if (currentUserEmail && createdByEmail) {
                return currentUserEmail == createdByEmail
            }
        }

        return false
    }

    private goToClosureReport(): void {
        this.$router.push({ name: 'ClosureReport', params: { id: this.$store.state.ideas.idea.id }});
    }

    private getReportDownloadLink(): string {
        return this.$store.state.ideas.closureReportExport.downloadPath;
    }

    private async downloadClosureReport(): Promise<void> {
        if(!this.$store.state.ideas.closureReportExport.downloadPath)
            await this.$store.dispatch(actions.EXPORT_CLOSURE_REPORT, this.$store.state.ideas.idea.id);
        window.open(this.getReportDownloadLink(), '_self');
    }

    get ideaInitiatorKid(): string {
        return this.idea?.createdBy;
    }

    get ideaStatusId(): number {
        return this.idea?.statusId;
    }

    get ideaBusinessDepartment(): string {
        return this.idea?.businessDepartment;
    }

    get editableScreensForInitiatorAndPO(): string [] {
        return ['Problem Statement', 'Vision Statement', 'Personas', 'Benefits'];
    }

    get shouldShowClosureReportButton(): boolean {
        const idea = this.$store.state.ideas.idea;
        if(idea.statusId === Status.InUse && !this.isClosureReportAvailable) {
            if(Permissions.isIdeaBusinessOwner()) {
                return idea.achievementOfUseCase !== 0 &&
                idea.achievementOfUseCase !== undefined &&
                idea.achievementOfUseCase !== null;
            }
            else {
                if(Permissions.isIdeaProductOwner()) {
                    return true;
                }
            }
        }
        return false;
    }

    get fieldsAreEditable(): boolean {
        const currentScreenName = this.currentScreen.name && this.currentScreen.name.trim() || '';
        const onboardingAssessment = 'Onboarding';

        if (Permissions.isAdmin() || Permissions.isIdeaProjectManager())
            return true;
        if (currentScreenName !== onboardingAssessment && Permissions.userBelongsToIdeaDepartment(this.ideaBusinessDepartment))
            return true;
        if (Permissions.isIdeaProductOwner() && this.editableScreensForInitiatorAndPO.includes(currentScreenName))
            return true;
        if (Permissions.isIdeaInitiator(this.ideaInitiatorKid)
                && (Permissions.ideaStatusIsSubmitted(this.ideaStatusId) || Permissions.ideaStatusIsDraft(this.ideaStatusId))
                && this.editableScreensForInitiatorAndPO.includes(currentScreenName))
            return true;

        return false;
    }

    get canEditCostsAndBenefits(): boolean {
        return Permissions.canEditCostsAndBenefits(this.ideaStatusId);
    }

    answers: IAnswerNestedEditDto[] = [];
    comments: IIdeaCommentNestedEditDto[] = [];
    onePager: any[] = [];
    open = true;
    relatedIdeas: RelatedIdea[] = [];

    private allQuestions: any[] = [];
    currentScreen: ScreenDtoWithScore = {};

    private validating = false;
    private NOT_AVAILABLE = 'N/A';
    costsAndBenefitsValid = true;
    showErrorsIfExists = false;
    ideaWasModified = false;
    showServiceEnablementRequired = false;

    @Watch('$route.query.q')
    onSearchQueryChanged(): void {
        if (this.$route.query.q && this.$route.query.q.length > 0) {
            this.$router.push({
                name: 'Home',
                query: {
                    view: 'list',
                    q: this.$route.query.q
                }});
        }
    }

    @Watch('$route.params.id')
    private async onIdChanged(to: any): Promise<void> {
        await this.getData(to);
    }

    @Watch('$store.state.ideas.idea.statusId')
    private onStatusChanged(): void {
        this.updateScreen();
    }

    public async getData(id = this.$route.params.id, refresh = false): Promise<void> {
        if(this.$store.state.ideas.idea.id != id || refresh) {
            await this.$store.dispatch(actions.LOAD_IDEA, id);
        }
        this.setCostsAndBenefitsReadOnly(this.ideaStatusId >= Status.DeliveryApproved);
        this.relatedIdeas = await this.loadRelatedIdeas(this.$route.params.id);
        await this.$store.dispatch('idea/getSubmitForm', id);
        this.$store.commit('idea/resetScore');

        this.$store.state.idea.screens.forEach((screen: IScreenDto) =>
            this.allQuestions.push(
                ...((screen || {}).questions || []).filter(question => question.id && !~notAQuestion.indexOf(question.id))
            )
        );

        //
        this.onePager = this.$store.state.ideas.idea.answers && this.$store.state.ideas.idea.answers.map((answer: IAnswerDto) => ({
            question: (this.allQuestions.find(question => question.id === answer.questionId) || {}).text,
            text: answer.content,
        })) || []

        this.mapAnswers();

        this.comments = this.$store.state.ideas.idea.ideaComments || [];

        this.updateScreen();
        this.initialIdea = JSON.stringify(ClearObject.clearEmptyNestedObjects(new IdeaForm(this.idea, this.answers)));
    }

    private handleIdeaChanged(): void {
        this.initialIdea = JSON.stringify(new IdeaForm(this.idea, this.answers));
        this.validating = false;
    }

    private updateScreen(): void {
        this.$store.state.ideas.idea.screen ? this.currentScreen = (this.screens
            .find(screen => screen.name === this.$store.state.ideas.idea.screen.name.split('/')[0]
            ) as ScreenDtoWithScore) || this.screens[0] : this.screens[0]
    }

    private mapAnswers(): void {
        function cleanAnswerValues(answer: any): any {
            const answerValues = (answer.answerValues || []).map((v: any) => new AnswerValueNestedEditDto({
                valueId: v.valueId,
                id: v.id,
                answerId: answer.id,
            })).filter((v: any) => v.valueId)

            return answerValues.length ? answerValues : [...answerValues]
        }

        this.answers = (this.$store.state.ideas.idea.answers || []).map((answer: IAnswerDto) => ({
            questionId: answer.questionId,
            content: answer.content,
            answerValues: cleanAnswerValues(answer),
            answerComments: answer.answerComments,
            id: answer.id,
            ideaId: answer.ideaId,
        }));
    }

    private get screens(): ScreenGroup[] { // eslint-disable-line
        const screens: ScreenGroup[] = []
        if (this.$store.state.ideas.idea.phase || this.canEditAnswers) {
            this.$store.state.idea.screens.forEach((screen: IScreenDto) => {

                const screenName = (screen || {}).name || ''
                const isGroup = !!~screenName.indexOf('/')
                const groupName = screenName.split('/')[0]

                const existingGroup = screens.find(screenGroup => screenGroup.name === groupName)
                const screenQuestionCount = screen.questions?.length

                if (screenQuestionCount && screenQuestionCount > 0) {
                    if (isGroup) {
                        existingGroup && existingGroup.group
                            ? existingGroup.group.push(screen)
                            : screens.push({
                                name: groupName,
                                group: [screen],
                            })
                    } else {
                        screens.push({
                            name: groupName,
                            group: [screen],
                        })
                    }

                    if (groupName == 'Stake Holders' && this.$store.state.ideas.idea.statusId == 2 && screen.questions && screen.questions?.length) {
                        for (const question of screen.questions) {
                            const existingAnswer = this.answers.find((answer: any) => answer.questionId === question.id)

                            if (!existingAnswer) {
                                this.answers.push({
                                    questionId: question.id,
                                    content: 'No',
                                })
                            }
                        }
                    }
                }
            })
        }

        const indexOfParked = screens.findIndex(screenGroup => screenGroup.name === 'Parked')

        // TODO: add this back when Killed and Parked come back into scope
        if (indexOfParked > -1 ) {
            screens.splice(indexOfParked, 2);
        }

        const indexOfStakeholderMapping = screens.findIndex(screenGroup => screenGroup.name === 'Stakeholders mapping')
        const indexOfEvaluated = screens.findIndex(screenGroup => screenGroup.name === 'Evaluation')

        if (indexOfStakeholderMapping > -1 && indexOfEvaluated > -1) {
            const stakeholderMapping = screens.splice(indexOfStakeholderMapping, 1);
            screens.splice(indexOfEvaluated + 1, 0, stakeholderMapping[0]);
        }

        if ((Permissions.canEditCostsAndBenefits || Permissions.canViewCostsAndBenefits())) {
            const costsAndBenefitsScreenNotAdded = !screens.find((screen: any) => screen.name === this.$t(this.costsAndBenefitsScreenNameKey));
            if (this.statusIsAtLeastEvaluated && costsAndBenefitsScreenNotAdded) {
                screens.push({ name: this.$t(this.costsAndBenefitsScreenNameKey) as string })
            }

            const realizedBenefitsScreenNotAdded = !screens.find((screen: any) => screen.name === this.$t(this.realizedBenefitsScreenNameKey));
            if (this.statusIsAtLeastEvaluated && realizedBenefitsScreenNotAdded) {
                screens.push({ name: this.$t(this.realizedBenefitsScreenNameKey) as string })
            }
        }

        return screens;
    }

    private get shouldShowScreen(): boolean | undefined {
        return this.allQuestions && this.allQuestions.length > 0 &&
            this.screens &&
            this.screens.length > 0 &&
            this.screens[0].group &&
            this.screens[0].group[0].questions &&
            this.screens[0].group[0].questions.length > 0
    }

    private async created(): Promise<void> {
        await this.getData();

        if(this.$store.state.ideas.idea.hasClosureReport)
            await this.$store.dispatch(actions.EXPORT_CLOSURE_REPORT, this.$store.state.ideas.idea.id);
        if (!this.$store.state.user.users.length) {
            const users = await new UserService().getAllPaged(this.userFilterModel);
            let userList = [];
            userList = users && users.result ? users.result.items : [];
            this.$store.commit(mutations.SET_USERS, this.mapUsers(userList));
        }

        if (this.initiatorIsCurrentUser || this.canEditAnswers || this.canEditStatus || this.canApprove) {
            if (this.canEditAnswers) {
                this.currentScreen = this.screens[0]
            }
        } else {
            // window.location.href = `${window.location.origin}/access-forbidden.html`;
        }
    }

    private mapUsers(users: any): any {
        return users.map((user: any) => ({
            label: user.name || user.email || user.kid,
            email: user.email,
            secondaryLabel: user.kid,
            value: `${user.id}`,
        }))
    }

    private async save(): Promise<void> {
        const canUpdateNewDeliveryDate = this.idea.statusId >= Status.DeliveryApproved &&
            this.idea.statusId <= Status.InImplementation;
        const canUpdateWbsElement = this.idea.statusId >= Status.DeliveryApproved;

        const idea: any = {
            id: this.$store.state.ideas.idea.id,
            answers: this.answers,
            ...canUpdateWbsElement && {wbsElement: this.idea.wbsElement},
            ...canUpdateNewDeliveryDate && {deliveryStatusId: this.idea.deliveryStatusId},
            ...canUpdateNewDeliveryDate && {newDeliveryEndDateComment: this.idea.newDeliveryEndDateComment},
            ...canUpdateNewDeliveryDate && {newDeliveryEndDate: this.idea.newDeliveryEndDate},
        }

        if (this.costsAndBenefits && (this.canUpdateCostsAndBenefits() || Permissions.isAdmin())) {
            idea.costsAndBenefits = CostsAndBenefitsTemplate.mapCostsAndBenefitsToDto(
                ClearObject.clearEmptyNestedObjects(this.costsAndBenefits));
        }
        if (this.realizedAndScaledBenefits && this.statusIsAtLeastEvaluated) {
            idea.realizedAndScaledBenefits = BenefitsTemplate.mapRealizedAndScaledBenefitsToDto(
                ClearObject.clearEmptyNestedObjects(this.realizedAndScaledBenefits), this.ideaStatusId);
        }

        const r = await this.$store.dispatch(actions.EDIT_IDEA, {
            id: this.$store.state.ideas.idea.id,
            data: {...idea}
        })

        if (r) {
            await this.getData(undefined, true);
            this.mapAnswers();
            this.initialIdea = JSON.stringify(new IdeaForm(this.idea, this.answers));
            this.validating = false;
            this.showErrorsIfExists = false;
        }

        if (this.shouldUpdateRelatedIdeasWidget(idea)) {
            await this.$store.dispatch(actions.LOAD_RELATED_IDEAS, this.$route.params.id);
        }

        if(!r && this.ideaStatusId >= Status.Prioritized) {
            this.showServiceEnablementRequired = true;
        }
    }

    private shouldUpdateRelatedIdeasWidget(idea: any): boolean {
        const screens = this.$store.state.idea.screens;
        const onboardingScreen = screens.filter((screen: any) => screen.name === 'Onboarding')[0];
        const relatedIdeasList = this.$store.state.ideas.relatedIdeas.map((idea: RelatedIdea) => idea.coodeId);
        const dependencyQuestionId = 13;
        const currentDependencyAnswers = idea.answers.filter(
            (answer: any) => answer.questionId === dependencyQuestionId)[0]?.answerValues.map(
                (answer: any) => AnswersHelper.getAnswerOptionById(onboardingScreen, answer.valueId)?.content);

        return JSON.stringify(relatedIdeasList?.sort()) !== JSON.stringify(currentDependencyAnswers?.sort());
    }

    gotoTab(tab: IScreenDto): void {
        this.currentScreen = tab
    }

    private get callToAction(): string {
        const callToActionTranslateKey = this.callToActionOptions.find(c => c.status === this.$store.state.ideas.idea.statusId)?.translateKey;
        return callToActionTranslateKey ? (this.$t(callToActionTranslateKey) as string) : '';
    }

    private async handleApproveButton(): Promise<void> {
        this.ideaWasModified = this.hasIdeaObjectChanged();
        if(this.ideaWasModified) {
            (this.$refs.unsavedChangesModal as UnsavedChangesModal).open();
        } else {
            await this.approve();
            this.initialIdea = JSON.stringify(new IdeaForm(this.idea, this.answers));
        }
    }

    private hasIdeaObjectChanged(): boolean {
        const currentIdea = ClearObject.clearEmptyNestedObjects(new IdeaForm(this.idea, this.answers));
        const initialIdea = ClearObject.clearEmptyNestedObjects(JSON.parse(this.initialIdea));

        if('costsAndBenefits' in currentIdea && !('costsAndBenefits' in initialIdea))
            delete currentIdea.costsAndBenefits;
        if('costsAndBenefits' in initialIdea && !('costsAndBenefits' in currentIdea))
            delete initialIdea.costsAndBenefits;
        if('realizedAndScaledBenefits' in currentIdea && !('realizedAndScaledBenefits' in initialIdea))
            delete currentIdea.realizedAndScaledBenefits;
        if('realizedAndScaledBenefits' in initialIdea && !('realizedAndScaledBenefits' in currentIdea))
            delete initialIdea.realizedAndScaledBenefits;
        return JSON.stringify(currentIdea) !== JSON.stringify(initialIdea);
    }

    private async handleChangeStatusAndSaveIdea(): Promise<void> {
        (this.$refs.unsavedChangesModal as UnsavedChangesModal).close();
        await this.save();
        await this.approve();
        this.initialIdea = JSON.stringify(new IdeaForm(this.idea, this.answers));
        (this.$refs.unsavedChangesModal as UnsavedChangesModal).close();
    }

    private checkFormValidity(): void {
        if (!this.isValid()) {
            MessageHandler.showToastMessage(this.$t('fillAllFields') as string, ToastMessageTypes.INFO);
            this.validating = true;
            this.markAnswersAsInvalid = true;
            this.scrollToElement(this.$refs.tabs)
            return
        }
        this.validating = false;
        this.markAnswersAsInvalid = false;
    }

    private async approve(): Promise<void> {
        this.checkFormValidity();
        if (!this.isValid() && this.ideaStatusId < Status.Evaluated || this.validating) {
            (this.$refs.unsavedChangesModal as UnsavedChangesModal).close();
            return;
        }

        const idea: any = {
            id: this.$store.state.ideas.idea.id,
            newStatus: this.$store.state.ideas.idea.actions[0],
            answers: this.answers,
        }

        if (this.costsAndBenefits && this.canUpdateCostsAndBenefits()) {
            idea.costsAndBenefits = CostsAndBenefitsTemplate.mapCostsAndBenefitsToDto(
                ClearObject.clearEmptyNestedObjects(this.costsAndBenefits));
        }
        if (this.realizedAndScaledBenefits && this.statusIsInRollout) {
            idea.realizedAndScaledBenefits = BenefitsTemplate.mapRealizedAndScaledBenefitsToDto(
                ClearObject.clearEmptyNestedObjects(this.realizedAndScaledBenefits), this.ideaStatusId);
        }
        (this.$refs.unsavedChangesModal as UnsavedChangesModal).close();
        const r = await this.$store.dispatch(actions.SUBMIT_IDEA, idea);
        if(r) {
            this.mapAnswers();
            (this.$refs.unsavedChangesModal as UnsavedChangesModal).close();
            this.showErrorsIfExists = false;
            if(idea.statusId >= Status.DeliveryApproved)
                this.setCostsAndBenefitsReadOnly(true);
        }
    }

    private canUpdateCostsAndBenefits(): boolean {
        return this.idea.statusId >= Status.Evaluated && Permissions.ideaStatusIsLowerThanDeliveryApproved(this.idea.statusId);
    }

    // validation
    private isValid(): boolean {
        ((this.$refs.screens || []) as Array<any>).map((screen: Screen) => screen.isValid());
        return this.screens.every(this.isValidScreen);
    }

    private isValidScreen(screen: ScreenGroup): boolean | undefined {
        const screenName = screen.name;

        switch (screenName) {
        case this.$t(this.costsAndBenefitsScreenNameKey):
            (this.$refs.costs_and_benefits as CostsAndBenefits).checkValidForm();
            if(this.ideaStatusId > Status.Evaluated)
                this.showErrorsIfExists = true;
            return this.costsAndBenefitsValid;
        case this.$t(this.realizedBenefitsScreenNameKey):
            this.showErrorsIfExists = true;
            return (this.$refs.realized_and_scaled_benefits as RealizedAndScaledBenefits).isValidRealizedAndScaledBenefits;
        default:
            return this.isValidGenericScreen(screen);
        }
    }

    private isValidGenericScreen(screen: ScreenGroup): boolean | undefined {
        if (
            this.canEditAnswers &&
            screen.group && screen.group.length > 0 &&
            screen.group[0].phaseId != undefined &&
            this.$store.state.ideas.idea.screen.phaseId != undefined &&
            screen.group[0].phaseId > this.$store.state.ideas.idea.screen.phaseId)
        {
            return true // If admin, turn off validation for screens with lower or equal phase than current status
        }
        return screen.group && screen.group.every(this.stepIsValid)
    }

    private stepIsValid(step: IScreenDto): boolean | undefined {
        return step.questions && step.questions.every(this.questionIsValid)
    }

    private questionIsValid(question: QuestionDto): boolean {
        if (question.isRequired) {
            const answer = this.getAnswer(question)
            const answerValue = this.getAnswerValue(question, undefined)

            if (
                !!answerValue ||
                (!!answer &&
                answer.replace(/\s/g, '').length > 0 &&
                answer !== this.NOT_AVAILABLE)) {
                return true
            }

            return false
        }

        return true
    }

    getAnswer(question: QuestionDto): string | undefined {
        const answers = this.answers
        const existingAnswer = answers.find((answer: any) => answer.questionId === question.id)

        if (existingAnswer) {
            return existingAnswer.content
        } else {
            return undefined
        }
    }
    getAnswerValue(question: QuestionDto, answerValue: any): string | boolean {
        const answers = this.answers
        const existingAnswer = answers.find((answer: any) => answer.questionId === question.id)
        const existingAnswerValue = existingAnswer ? existingAnswer.answerValues || [] : []

        if (answerValue === undefined) {
            return !!existingAnswerValue.length
        }

        return existingAnswerValue.find(i => i.valueId === answerValue.id) ? 'X' : ''
    }

    private async onCommentAdded(): Promise<void> {
        // await this.save();
        // this.comments = this.$store.state.ideas.idea.ideaComments || [];
        this.getData(undefined, true);
    }

    private async onCommentDeleted(): Promise<void> {
        this.getData(undefined, true);
    }

    private handleEdit(editMode: boolean): void {
        this.editMode = editMode;

        if (editMode) {
            this.currentScreen = this.screens[0]
        }
    }

    private handleDeliveryEndDateChanged(date: string): void {
        if(this.idea.costsAndBenefits.projectEndDate !== date)
            this.idea.newDeliveryEndDate = date;
    }

    private scrollToElement(ref: any): void {
        if (ref) {
            ref.scrollIntoView({ behavior: 'smooth' });
        }
    }

    get statusIsAtLeastEvaluated(): boolean {
        return this.$store.state.ideas.idea.statusId >= Status.Evaluated;
    }

    get statusIsInRollout(): boolean {
        return this.$store.state.ideas.idea.statusId === Status.InImplementation;
    }
}
