








































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    name: 'StakeHolderCustom',
})
export default class StakeHolderCustom extends Vue {
    private answer = '';

    private AFFIRMATIVE = 'Yes';

    private NEGATIVE = 'No';

    private NOT_AVAILABLE = 'N/A';

    private def = '';

    @Prop()
    private disabled!: boolean;

    @Prop()
    private question: any;

    private mounted(): void {
        this.answer = this.$attrs['answer'];
        this.def = this.answer ? this.answer: this.NEGATIVE;
        this.answer = this.answer ? this.answer: this.def;
    }
}

