import Vue from 'vue'
import Component from 'vue-class-component'
import { DeliveryTypesServiceProxy, DeliveryStrategiesServiceProxy, CostTypesServiceProxy,
    DeliveryTypeListDto, DeliveryStrategyDto, CostTypeListDto, StrategicThemeListItemDto,
    StrategicThemeServiceProxy, CostCentersServiceProxy, CostCenterListDto,
} from '@/service-proxies/service-proxies.g';
import { DeliveryTeam, SelectOption } from '@/models';
import { DeliveryTeamService } from '@/services/delivery-team-service';
import MessageHandler from '@/utils/message-handler';

const deliveryTeamsApi = new DeliveryTeamService();
const deliveryTypesApi = new DeliveryTypesServiceProxy();
const deliveryStrategyApi = new DeliveryStrategiesServiceProxy();
const costTypesApi = new CostTypesServiceProxy()
const strategicThemesApi = new StrategicThemeServiceProxy()
const costCentersApi = new CostCentersServiceProxy();

const loading = 'loading';

@Component
export default class Loaders extends Vue {
    deliveryOrganizations: SelectOption[] = [];
    deliveryTypes: SelectOption[] = [];
    deliveryStrategies: SelectOption[] = [];
    costTypes: SelectOption[] = [];
    strategicThemes: SelectOption[] = [];
    costCenters: SelectOption[] = [];

    async mounted(): Promise<void> {
        this.$store.commit(loading, null, { root: true });
        await Promise.all([
            this.loadDeliveryOrganizations(),
            this.loadDeliveryTypes(),
            this.loadDeliveryStrategies(),
            this.loadCostTypes(),
            this.loadStrategicThemes(),
            this.loadCostCenters(),
        ]);
        this.$store.commit(loading, null, { root: true });
    }
    private async loadDeliveryOrganizations(): Promise<void> {
        try {
            const deliveryTeams = await deliveryTeamsApi.getDeliveryTeams();
            if (deliveryTeams) {
                this.deliveryOrganizations = deliveryTeams.items.map((item: DeliveryTeam) => ({
                    label: item.name,
                    value: item.id
                }));
            }
        } catch(error: any) {
            MessageHandler.showErrorMessage(error);
        }
    }

    private async loadDeliveryTypes(): Promise<void> {
        try {
            const deliveryTypes = await deliveryTypesApi.listDeliveryType();
            if (deliveryTypes?.result?.items) {
                this.deliveryTypes = deliveryTypes.result.items.map((item: DeliveryTypeListDto) => ({
                    label: item.title,
                    value: item.id
                }));
            }
        } catch(error: any) {
            MessageHandler.showErrorMessage(error);
        }
    }

    private async loadDeliveryStrategies(): Promise<void> {
        try {
            const deliveryStrategies = await deliveryStrategyApi.listDeliveryStrategy();
            if (deliveryStrategies?.result?.items) {
                this.deliveryStrategies = deliveryStrategies.result.items.map((item: DeliveryStrategyDto) => ({
                    label: item.title,
                    value: item.id
                }));
            }
        } catch(error: any) {
            MessageHandler.showErrorMessage(error);
        }
    }

    private async loadCostTypes(): Promise<void> {
        try {
            const costTypes = await costTypesApi.listCostType();
            if (costTypes?.result?.items) {
                this.costTypes = costTypes.result.items.map((item: CostTypeListDto) => ({
                    label: item.title,
                    value: item.id
                }));
            }
        } catch(error: any) {
            MessageHandler.showErrorMessage(error);
        }
    }

    private async loadStrategicThemes(): Promise<void> {
        try {
            const strategicThemes = await strategicThemesApi.listStrategicThemes();
            if (strategicThemes?.result?.items) {
                this.strategicThemes = strategicThemes.result.items.map((item: StrategicThemeListItemDto) => ({
                    label: item.title,
                    value: item.id
                }));
            }
        } catch(error: any) {
            MessageHandler.showErrorMessage(error);
        }
    }

    private async loadCostCenters(): Promise<void> {
        try {
            const costCenters = await costCentersApi.listCostCenter();
            if (costCenters?.result?.items) {
                this.costCenters = costCenters.result.items.map((item: CostCenterListDto) => ({
                    label: item.title,
                    value: item.id
                }));
            }
        } catch(error: any) {
            MessageHandler.showErrorMessage(error);
        }
    }
}
