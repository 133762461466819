


























































import moment from 'moment';
import { Component, Vue, Prop} from 'vue-property-decorator';

@Component({
    name: 'tangible-benefits-table',
})
export default class TangibleBenefitsTable extends Vue {

    @Prop({required: true})
    tangibleBenefits!: any;

    @Prop({required: true, default: null })
    projectStartDate!: any;

    private tableColumnHeaders = {
        empty: '',
        total: this.$t('costsAndBenefits.total'),
    }

    getYear(yearStep: number): string {
        return moment(this.projectStartDate || undefined).add(yearStep, 'y').year().toString();
    }

    get realizedBenefits(): any[] {
        return this.tangibleBenefits?.realizedBenefits;
    }

    get scaledBenefits(): any[] {
        return this.tangibleBenefits?.scaledBenefits;
    }

    get tableConfig(): any  {
        return {
            options: {
                sortable: []
            },
            columns: [
                this.tableColumnHeaders.empty,
                this.getYear(0),
                this.getYear(1),
                this.getYear(2),
                this.getYear(3),
                this.getYear(4),
                this.tableColumnHeaders.total,
            ],
            data: [{
                empty: this.$t('closureReportForm.realizedBenefits') as string,
                ...this.realizedBenefits,
                total: this.tangibleBenefits?.totalRealizedBenefits
            },
            {
                empty: this.$t('closureReportForm.scaledBenefits') as string,
                ...this.scaledBenefits,
                total: this.tangibleBenefits?.totalScaledBenefits
            }],
        }
    }
}

