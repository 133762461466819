'use strict';

import Years from '@/components/screen/common/years';
import YearsDto from './years-dto';

export default class YearCalculations {
    public static addYearValues(currentValue: YearsDto, valueToAdd: any | null): void {
        if (!valueToAdd) return;

        currentValue[Years.year0] += Number(valueToAdd[Years.year0]) || 0;
        currentValue[Years.year1] += Number(valueToAdd[Years.year1]) || 0;
        currentValue[Years.year2] += Number(valueToAdd[Years.year2]) || 0;
        currentValue[Years.year3] += Number(valueToAdd[Years.year3]) || 0;
        currentValue[Years.year4] += Number(valueToAdd[Years.year4]) || 0;
    }

    public static substractYearValues(yearValues1: YearsDto, yearValues2: YearsDto): any {
        return {
            [Years.year0]: Number(yearValues1[Years.year0] - yearValues2[Years.year0]).toFixed(2),
            [Years.year1]: Number(yearValues1[Years.year1] - yearValues2[Years.year1]).toFixed(2),
            [Years.year2]: Number(yearValues1[Years.year2] - yearValues2[Years.year2]).toFixed(2),
            [Years.year3]: Number(yearValues1[Years.year3] - yearValues2[Years.year3]).toFixed(2),
            [Years.year4]: Number(yearValues1[Years.year4] - yearValues2[Years.year4]).toFixed(2),
        }
    }

    public static sumOfYearValues(yearValues: YearsDto): string {
        return Number(
            Number(yearValues[Years.year0])
            + Number(yearValues[Years.year1])
            + Number(yearValues[Years.year2])
            + Number(yearValues[Years.year3])
            + Number(yearValues[Years.year4])
        ).toFixed(2);
    }
}
