









































































































































































































































































































import { SelectOption } from '@/models';
import { Component, Vue } from 'vue-property-decorator';
import TangibleBenefitsTable from '@/components/screen/closure-report/tangible-benefits-table.vue';
import ActualCostsTable from '@/components/screen/closure-report/actual-costs-table.vue';
import RejectRequestModal from '@/components/screen/closure-report/reject-request-modal.vue';
import { actions, getters } from '@/store/types';
import { mapActions, mapGetters, mapState } from 'vuex';
import Permissions from '@/utils/helpers/permisssions';
import ConfirmationScreen from '@/components/widgets/confirmation-screen.vue';
import moment from 'moment';
import MessageHandler from '@/utils/message-handler';
import { ToastMessageTypes } from '@/mixins/toast-message/toast-message-types';

const DATE_FORMAT = 'YYYY-MM-DD';

@Component({
    name: 'closure-report',
    components: {
        TangibleBenefitsTable,
        ActualCostsTable,
        RejectRequestModal,
        ConfirmationScreen,
    },
    computed: {
        ...mapGetters({
            benefitTypes: getters.GET_BENEFIT_TYPES,
            recipientsBusinessUnits: getters.GET_SERVICE_RECIPIENTS_BUSINESS_UNITS,
        }),
        ...mapState({
            storeClosureReport: (state: any) => state.ideas.closureReport,
        }),
    },
    methods: {
        ...mapActions({
            loadServiceRecipientsBusinessUnits: actions.LOAD_SERVICE_RECIPIENTS_BUSINESS_UNITS,
            loadBenefitTypes: actions.LOAD_BENEFIT_TYPES,
        })
    }
})
export default class ClosureReport extends Vue {
    private achievementOptions = new Array(10).fill(0).map((_,i)=>(i+1)*10);
    private currentRestrictionTextCount = 0;
    private currentRecommendationsTextCount = 0;
    private currentLessonsTextCount = 0;
    private maxCommentLength = 500;
    private maxAchievement = 100;
    private storeClosureReport!: any;
    benefitTypes!: Array<SelectOption>;
    recipientsBusinessUnits!: Array<SelectOption>;

    loadServiceRecipientsBusinessUnits!: () => Promise<void>;
    loadBenefitTypes!: () => Promise<void>;

    private isRestrictionsInputValid = true;
    private isAchievementInputValid = true;
    private showOverviewButton = false;
    private isReadonly = false;
    private showConfirmationScreen = false;

    private async mounted(): Promise<void> {
        await this.$store.dispatch(actions.LOAD_CLOSURE_REPORT, this.$route.params.id);
        this.initializeClosureReport();
        if(!this.benefitTypes.length || !this.recipientsBusinessUnits.length) {
            await Promise.all([
                this.loadServiceRecipientsBusinessUnits(),
                this.loadBenefitTypes(),
            ]);
        }
    }

    private initializeClosureReport(): void {
        this.currentRestrictionTextCount = this.storeClosureReport?.restrictions?.length ?? 0;
        this.currentRecommendationsTextCount = this.storeClosureReport?.recommendations?.length ?? 0;
        this.currentLessonsTextCount = this.storeClosureReport?.lessonsLearned?.length ?? 0;
        this.isReadonly = this.getReadonlyAccess();
    }

    private getReadonlyAccess(): boolean{
        if(Permissions.isIdeaProductOwner())
            return !this.storeClosureReport.isClosureReportEditable;
        else {
            if(Permissions.isIdeaBusinessOwner()) {
                return true;
            }
        }
        return true;
    }

    get tangibleBenefits(): any {
        return this.storeClosureReport?.costAndBenefits?.tangibleBenefits;
    }

    get actualCosts(): any {
        return this.storeClosureReport?.costAndBenefits?.actualCosts;
    }

    private getBenefitTypeName(optionId: number): string | undefined {
        return this.benefitTypes ? this.benefitTypes.filter((type: SelectOption) => type.value === optionId)[0]?.label : '';
    }

    getAchievementOptions(): any[] {
        return this.achievementOptions.map((value: number): SelectOption => ({
            label: `${value}%`,
            value: value
        }))
    }

    private handleRestrictionsInput(value: string): void {
        this.currentRestrictionTextCount = value.length;
        this.isRestrictionsInputValid = value.length > 0;
        this.storeClosureReport.restrictions = value;
    }

    private handleRecommendationsInput(value: string): void {
        this.storeClosureReport.recommendations = value;
        this.currentRecommendationsTextCount = value.length;
    }

    private handleLessonsInput(value: string): void {
        this.storeClosureReport.lessonsLearned = value;
        this.currentLessonsTextCount = value.length;
    }

    private handleAchievementChange(value: number): void{
        this.storeClosureReport.achivementOfUseCase = value;
        if(value)
            this.isAchievementInputValid = true;
        if(value === this.maxAchievement)
            this.storeClosureReport.restrictions = null;
    }

    private async requestSign(): Promise<void> {
        if(this.storeClosureReport.achivementOfUseCase) {
            if(this.storeClosureReport.achivementOfUseCase === this.maxAchievement
                || this.storeClosureReport.achivementOfUseCase < this.maxAchievement
                && this.storeClosureReport.restrictions) {
                await this.$store.dispatch(actions.SUBMIT_CLOSURE_REPORT,this.storeClosureReport)
                    .then((response: any) => { response ?  this.handleRequestSentSuccesfully() : this.handleReportRequestError() });
            } else {
                this.isRestrictionsInputValid = false;
                this.scrollToElement(this.$refs.restrictionsField);
            }
        } else {
            this.isAchievementInputValid = false;
            this.scrollToElement(this.$refs.achievementField);
        }

    }

    private scrollToElement(ref: any): void {
        const {$el} = ref;
        if ($el) {
            $el.scrollIntoView({ behavior: 'smooth' });
        }
    }

    private getServiceName(id: number): string {
        const unit = this.recipientsBusinessUnits.filter((unit: SelectOption) => unit.value === id)[0];
        if(unit?.label)
            return unit?.label;
        return '';
    }

    private handleRequestSentSuccesfully(): void {
        MessageHandler.showToastMessage(this.$t('closureReportForm.signRequestSent') as string, ToastMessageTypes.SUCCESS);
        this.showOverviewButton = true;
        this.isReadonly = true;
    }

    private gotoHome(): void {
        this.$router.push({
            name: 'Home',
        });
    }

    private handleReportRequestError(): void {
        if(this.storeClosureReport.achievementOfTheUseCaseObjective < 100)
            this.isRestrictionsInputValid = this.storeClosureReport.restrictions.length > 0;
    }

    private get showClosureReportActionBtns(): boolean {
        if(this.storeClosureReport.hasClosureReport)
            return false;
        if(Permissions.isIdeaProductOwner() && !this.isReadonly) {
            return true;
        }
        if(Permissions.isIdeaBusinessOwner() && !this.storeClosureReport.isClosureReportEditable) {
            return true;
        }
        return false;
    }

    get closureReportBtnsLabel(): string[] {
        if (Permissions.isIdeaProductOwner() && !Permissions.isIdeaBusinessOwner()) {
            return [
                this.$t('cancel') as string,
                this.$t('closureReportForm.requestSign') as string
            ];
        }
        return [
            this.$t('closureReportForm.reject') as string,
            this.$t('closureReportForm.sign') as string,
        ];
    }

    private closureReportBtnsAction(btnLabel: string): void {
        switch(btnLabel){
        case this.$t('cancel') as string:
            this.closeReportPage();
            break;
        case this.$t('closureReportForm.requestSign') as string:
            this.requestSign();
            break;
        case this.$t('closureReportForm.reject') as string:
            this.rejectClosureReportRequest();
            break;
        case this.$t('closureReportForm.sign') as string:
            this.handleSigningOfClosureReport(true);
            break;
        default:
            break;
        }
    }

    private async handleClosureReportRejection(comment: string): Promise<void> {
        await this.handleSigningOfClosureReport(false, comment);
    }

    private async handleSigningOfClosureReport(isApproved: boolean, comment?: string): Promise<void> {
        await this.$store.dispatch(actions.SIGN_CLOSURE_REPORT, {
            ideaId: Number(this.$route.params.id),
            businessOwnerId: this.storeClosureReport.businessOwnerId,
            isSigned: isApproved,
            reasonsForReject: comment
        }).then((response: any) => { response ?  this.signClosureReport(isApproved) : '' });
    }

    private signClosureReport(approved: boolean): void {
        this.showConfirmationScreen = approved;
        this.showOverviewButton = true;
    }

    private rejectClosureReportRequest(): void {
        (this.$refs.rejectRequestModal as RejectRequestModal).open();
    }

    private async handleDownloadClosureReport(): Promise<void> {
        await this.$store.dispatch(actions.EXPORT_CLOSURE_REPORT, this.$store.state.ideas.idea.id);
        this.downloadClosureReport();
    }

    private getReportDownloadLink(): string {
        return this.$store.state.ideas.closureReportExport.downloadPath;
    }

    private async downloadClosureReport(): Promise<void> {
        window.open(this.getReportDownloadLink(), '_self');
    }

    private formatDate(date: any): string {
        return moment(date).format(DATE_FORMAT);
    }

    private closeReportPage(): void {
        this.$router.push({name: 'IdeaEdit', params: { id: this.$store.state.ideas.idea.id }});
    }
}

