import moment from 'moment';

export default class ClearObject {
    public static clearEmptyNestedObjects(obj: any): any {
        if (obj == null) return null;
        const clonedObj = JSON.parse(JSON.stringify(obj));
        return this.removeEmptyObjects(clonedObj);
    }

    private static removeEmptyObjects(obj: any): any {
        const objType = typeof obj;
        if (objType === 'string'
            || objType === 'number'
            || this.isIsoDate(obj)
            || obj == null) {
            return obj;
        }

        if (Array.isArray(obj)) {
            obj = this.removeArrayEmptyItems(obj);
        } else if (objType === 'object') {
            if (this.hasEmptyProperties(obj)) {
                obj = null;
            } else {
                Object.keys(obj).forEach((key: any) => obj[key] = this.removeEmptyObjects(obj[key]));
            }
        }

        return obj;
    }

    private static removeArrayEmptyItems(array: any): any {
        return array.filter((el: any | null) =>  {
            if (el == null || (typeof el === 'object' && this.hasEmptyProperties(el))) {
                return false;
            }
            return true;
        });
    }

    private static hasEmptyProperties(obj: any): boolean {
        return (Object.keys(obj).length === 0)
            || Object.keys(obj).every((key: any) => obj[key] == null || obj[key] === '');
    }

    private static isIsoDate(date: any): boolean {
        return moment(date, moment.ISO_8601, true).isValid()
    }

}
