

























import { RelatedIdea } from '@/models/related-idea';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'related-ideas-list',
})
export default class RelatedIdeasList extends Vue {

    @Prop({required: true, default: () => []})
    private relatedIdeas!: RelatedIdea[];

    private getIdeaTitle(idea: RelatedIdea): string {
        return idea?.coodeId + ' ' + idea?.title;
    }
}
