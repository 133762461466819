






























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { CostsAndBenefitsValueItemBenefitDto } from '@/service-proxies/service-proxies.g';
import YearsHeader from '@/components/screen/common/years-header.vue';
import YearsInputs from '@/components/screen/common/years-inputs.vue';

@Component({
    name: 'direct-benefits-it',
    components: {
        YearsHeader,
        YearsInputs,
    },
})

export default class DirectBenefitsIt extends Vue {
    @Prop({required: true, default: null })
    projectStartDate!: any;

    @Prop({required: true, default: null })
    itBenefits!: Array<CostsAndBenefitsValueItemBenefitDto>;

    @Prop({required: true, default: null })
    yearsForecast!: number;

    @Prop({required: true })
    editEnabled!: boolean;

    @Prop({required: true, default: false })
    showErrorsIfExists!: boolean;

    @Prop({required: true, default: true })
    invalidBenefitsIt!: boolean;

    @Watch('itBenefits', { deep: true })
    itBenefitsChanged(itBenefits: any): void {
        this.$emit('itBenefitsChange', itBenefits);
    }
}
