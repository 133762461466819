







import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    name: 'ScreenTotalCalculation',
})
export default class ScreenTotalCalculation extends Vue {
    @Prop()
    private scores: any;

    private get score(): number{
        let totalScore = (this.scores || []).length
            ? 1
            : 0
      
        ;(this.scores || []).forEach((score: any) => ( totalScore = totalScore * score.score ))

        return totalScore
    }
}

