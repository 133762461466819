

























































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import {  mapGetters, mapState } from 'vuex';
import { Status, IAnswerDto, IdeaGetResponse, IStatusDto, OnePagerQuestionDto } from '@/service-proxies/service-proxies.g';

import { STATUS } from '@/components/status/status.vue';
import OnePagerPreview from '@/components/widgets/one-pager-preview.vue';
import GenerateImage from '@/utils/generate-image';

import { UserService } from '@/services/user-service';
import { Application, UserFilterModel } from '@/models';

import PermissionNames from '@/utils/permission-names';
import { actions, getters, mutations } from '@/store/types';
import Permissions from '@/utils/helpers/permisssions';

const ignoreAnswers = [
    'Required Next Steps and Updates from COODE',
]

const statusFilterHide = [
    'Draft',
    // 'Killed',
    // 'Parked',
]

@Component({
    name: 'one-pager',
    components: {
        OnePagerPreview,
    },
    computed: {
        ...mapState({
            idea: (state: any) => state.ideas.idea,
            statuses: (state: any) => state.dashboard.statuses,
        }),
        ...mapGetters({
            useCases: getters.GET_USECASES,
        })
    }
})
export default class OnePager extends Vue {
    private useCases!: Array<Application>;
    private idea!: IdeaGetResponse;
    private statuses!: IStatusDto[];
    private answers: any[] = [];

    private previewActive = false;
    private showEditButton = false;

    private statusId: Status | null = null;
    private relatedUseCaseId: number | null = null;
    private createdBy: any = {};

    private filteredUsers: {label: string; email: string; secondaryLabel: string; value: string}[] = []
    private initiatorObject: {label?: string; email?: string; secondaryLabel?: string; value?: string} | undefined = { label: 'N/A', value: '' }

    private initiatorID: string | undefined = 'N/A'
    private createdByUserID: string | undefined = 'N/A'
    private initiatorLoaded = false

    private usersAreLoading = false

    get editMode(): boolean {
        return this.canUpdateIdeaStatus;
    }

    private get Statuses(): any {
        const statuses = this.statuses.filter(status => status.name && !~statusFilterHide.indexOf(status.name))
            .map(status => ({ label: status.name, value: status.id }));

        if (Permissions.isIdeaProjectManager()
            && !Permissions.isAdmin()
            && !(Permissions.isCoodeConnector() && Permissions.hasUpdateStatusPermission())) {
            return this.filterStatusesForDeliveryOwner(statuses);
        }
        return statuses;
    }

    private filterStatusesForDeliveryOwner(statuses: any): any {
        return statuses.filter((item: any) => {
            if (item?.value && this.statusId)
                return item.value >= this.statusId;
        })
    }

    private hasPermission(permission: string): boolean {
        if (this.$store.state.user.permissions) {
            return !!~this.$store.state.user.permissions.indexOf(permission);
        }

        return false
    }

    private get canUpdateIdeaStatus(): boolean {
        return Permissions.canUpdateIdeaStatus();
    }

    private get canUpdateRelatedUseCase(): boolean {
        return Permissions.canUpdateRelatedUseCase();
    }

    get isAdmin(): boolean {
        return Permissions.isAdmin();
    }

    private get canApprove(): boolean {
        return this.hasPermission(PermissionNames.ApproveIdea);
    }

    private get canDelete(): boolean {
        return Permissions.canDeleteIdea(this.idea);
    }

    private get useCaseList(): any[] {
        const useCases = this.useCases.map((item: any) => ({
            label: item.name,
            value: item.id
        }));
        useCases.unshift({ label: this.$t('none') as string, value: null })
        return useCases;
    }

    /**
     * List of all questions.
     */
    @Prop()
    private allQuestions!: any[];

    private async created(): Promise<void> {
        if (!this.$store.state.dashboard.statuses || this.$store.state.dashboard.statuses.length === 0) {
            await this.$store.dispatch('dashboard/getStatuses');
        }

        if (!this.$store.state.dashboard.phases || this.$store.state.dashboard.phases.length === 0) {
            await this.$store.dispatch('dashboard/getPhases');
        }

        this.answers = this.idea.answers && this.idea.answers.map((answer: IAnswerDto) => ({
            question: (this.allQuestions.find(question => question.id === answer.questionId) || {}).text,
            text: answer.content,
        })) || [];

        this.statusId = (this.idea.statusId as number);
        this.relatedUseCaseId = (this.idea.useCaseId as number);
        this.createdBy.email = this.idea.createdBy;

        this.initiatorID = await this.initiator()
        this.createdByUserID = await this.createdByUser()

        this.initiatorLoaded = true
    }

    private get questions(): OnePagerQuestionDto[] | undefined {
        return (this.idea.onePagerQuestions || []).filter(question => question.title && !~ignoreAnswers.indexOf(question.title));
    }

    get getStatusLabel(): string {
        const status = this.statuses.find((s: IStatusDto) => s.id === (this.statusId || this.idea.statusId));
        return status && status.name ? status.name : '';
    }

    get getRelatedUseCaseLabel(): string {
        const related = this.useCases.find((useCase: any) => useCase?.id === this.relatedUseCaseId)
        return related && related.name ? related.name : 'N/A';
    }

    get getCreatedAt(): string {
        return this.idea.createdAt ? this.idea.createdAt.toDateString() : '';
    }

    private get statusColor(): any {
        return {
            backgroundColor: (STATUS as any)[this.getStatusLabel],
        }
    }

    private async usersFiltered(byTerm: string | undefined): Promise<{label: string; email: string; secondaryLabel: string; value: string}[]> {
        const userFilterModel: UserFilterModel = {
            page: 1,
            size: 300,
            term: byTerm ? byTerm : '',
            userGroupId: null,
            sortColumn: 1,
            sortDirection: 'Ascending',
        };

        this.usersAreLoading = true

        const users = await new UserService().getAllPaged(userFilterModel);
        let userList = [];
        userList = users && users.result ? users.result.items : [];

        const usersMappedForDropdown = userList.map((user: any) => ({
            label: user.name || user.email || user.kid,
            email: user.email,
            secondaryLabel: user.kid,
            value: `${user.id}`,
        }))

        this.usersAreLoading = false

        return usersMappedForDropdown
    }

    private async download(): Promise<void> {
        this.$store.commit('loading');
        this.previewActive = true;
        await this.$nextTick();
        this.$refs.onePager && await (new GenerateImage).downloadImage((this.$refs.onePager as HTMLElement), 'one-pager-coode-' + this.idea.id);
        this.previewActive = false;
        this.$store.commit('loading');

    }

    private preview(): void {
        this.previewActive = !this.previewActive;
    }

    private async deleteIdea(): Promise<void> {
        if(confirm(this.$t('deleteConfirmation') as string + this.idea.coodeId + '?') ) {
            const deletedSuccessfully = await this.$store.dispatch(actions.DELETE_IDEA, this.idea.id)

            if (deletedSuccessfully) {
                this.gotoHome()
            }
        }
    }

    private async handleEdit(): Promise<void> {
        if (this.editMode) {
            try {
                const createdBy = {
                    email: this.createdBy.email || this.createdBy.kid,
                    fullName: this.createdBy.fullName,
                    kid: this.createdBy.kid
                }
                await this.$store.dispatch(actions.EDIT_ONE_PAGER, {
                    id: this.idea.id,
                    data: {
                        id: this.idea.id,
                        statusId: this.statusId,
                        initiator: createdBy.email ? createdBy : undefined,
                    }
                });
                this.$root.$emit('ideaChanged');
                if(this.statusId)
                    this.$store.commit(mutations.SET_IS_COSTS_AND_BENEFITS_READ_ONLY, this.statusId >= Status.DeliveryApproved);
            } catch (e) {
                // this.statusId = (this.idea.statusId as Status);
                this.setStatus((this.idea.statusId as Status), true);
                const initiator = await this.initiator()

                if (initiator) {
                    this.setInitiator([initiator], true);
                }
            }
        }

        // this.editMode = !this.editMode;
        // this.$emit('changedEditMode', this.editMode);
    }

    private setStatus(statusId: Status, reset: boolean): void {
        this.statusId = statusId;

        reset || this.handleEdit();
    }

    private async setRelatedUseCase(useCaseId: number): Promise<void> {
        this.relatedUseCaseId = useCaseId;
        await this.$store.dispatch(actions.ADD_RELATED_USECASE, {
            id: this.idea.id,
            useCaseId: this.relatedUseCaseId
        });
    }

    private async initiator(): Promise<string | undefined> {
        const userIdentifier = this.idea.createdByEmail || this.idea.createdBy || this.idea.createdByFullName

        if (userIdentifier) {
            const match = await this.getUserValueForTerm(userIdentifier)
            if (match && match.value) {
                this.initiatorObject = match
                return match.value
            }
        }

        return 'N/A'
    }

    private async createdByUser(): Promise<string | undefined> {
        const match = await this.getUserValueForTerm(this.createdBy.email)

        if (match && match.value) {
            if (!this.initiatorObject || !this.initiatorObject.value) {
                this.initiatorObject = match
            }

            return match.value
        }

        return 'N/A'
    }

    private async getUserValueForTerm(term: string): Promise<{label?: string; email?: string; secondaryLabel?: string; value?: string} | undefined> {
        if (term && term.length > 0) {
            let termToSearch = ''

            if (term.split('@').length > 0) {
                const separatedEmailArray = term.split('@')
                termToSearch = separatedEmailArray[0]
            } else {
                termToSearch = term
            }

            const userFilterModel: UserFilterModel = {
                page: 1,
                size: 1,
                term: termToSearch,
                userGroupId: null,
                sortColumn: 1,
                sortDirection: 'Ascending',
            };

            this.usersAreLoading = true

            const users = await new UserService().getAllPaged(userFilterModel);
            this.usersAreLoading = false

            if (users && users.result && users.result.items && users.result.items.length > 0) {
                const user = users.result.items[0]

                return {
                    label: user.name || user.email || user.kid || 'N/A',
                    email: user.email,
                    secondaryLabel: user.kid,
                    value: `${user.id}`,
                }
            }
        }

        return undefined
    }

    private setInitiator(selectedUserArray: any[], reset: boolean): void {
        if (selectedUserArray && selectedUserArray.length > 0 ) {
            const user = selectedUserArray[0];

            if (user) {
                this.createdBy = {
                    fullName: user.label,
                    email: user.email,
                    kid: user.secondaryLabel,
                }

                this.initiatorObject = user
                this.initiatorID = user.value
            }

            reset || this.handleEdit();
        }
    }

    private async onUserTypeAheadSearch(e: any): Promise<void> {
        this.filteredUsers = await this.usersFiltered(e)
    }

    private onUserTypeAheadAbort(): void {
        this.usersAreLoading = false
    }

    private preventDropdownClose(e: any): void {
        if (e.path[0] && ~e.path[0].className.toString().indexOf('scroll')) {
            e.preventDefault();
        }
    }

    private gotoHome(): void {
        this.$router.push({
            name: 'Home',
        });
    }
}

