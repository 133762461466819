







































































































































































import { Component, Watch, Prop } from 'vue-property-decorator';
import { mapGetters, mapActions } from 'vuex';
import { actions, getters } from '@/store/types';
import { CostsAndBenefitsValueItemDto, CostsAndBenefitsEditDto, Status } from '@/service-proxies/service-proxies.g';
import moment from 'moment';
import YearsHeader from '@/components/screen/common/years-header.vue';
import YearsInputs from '@/components/screen/common/years-inputs.vue';
import Loaders from './loaders';
import CostBenefitOverview from './cost-benefit-overview.vue';
import General from './general/index.vue';
import DeliverySpecificInformation from './delivery-specific-information.vue';
import FinancialAssumptions from './financial-assumptions.vue';
import IndirectBenefits from '@/components/screen/common/indirect-benefits.vue';
import TangibleBenefitsBusiness from '@/components/screen/common/tangible-benefits-business.vue'
import DirectBenefitsIt from './direct-benefits-it.vue';
import DevelopmentAndImplementationCost from './development-and-implementation-cost.vue';
import Value from './value.vue';
import Years from '@/components/screen/common/years';
import CostsAndBenefitsTemplate from './costs-and-benefits-template'
import Permissions from '@/utils/helpers/permisssions';
import ServiceEnablement from './service-enablement.vue';
import ApplicationSupport from './application-support.vue';
import { CostsAndBenefitsValueItem } from '@/models/costs-and-benefits/costs-and-benefits-value-item';

@Component({
    name: 'costs-and-benefits',
    components: {
        CostBenefitOverview,
        General,
        DeliverySpecificInformation,
        FinancialAssumptions,
        IndirectBenefits,
        YearsHeader,
        YearsInputs,
        TangibleBenefitsBusiness,
        DirectBenefitsIt,
        DevelopmentAndImplementationCost,
        Value,
        ServiceEnablement,
        ApplicationSupport
    },
    computed: mapGetters({
        storeCostsAndBenefits: getters.GET_IDEA_COSTS_AND_BENEFITS,
        ideaStatusId: getters.GET_IDEA_STATUS_ID,
        isCostsAndBenefitsReadOnly: getters.GET_IS_COSTS_AND_BENEFITS_READ_ONLY
    }),

    methods: {
        ...mapActions({
            setIdeaCostsAndBenefits: actions.EDIT_IDEA_COSTS_AND_BENEFITS,
        })
    },
})
export default class CostsAndBenefits extends (Loaders) {
    @Prop({required: true, default: false })
    readonly showErrorsIfExists!: boolean;

    @Prop({required: true, default: false })
    readonly showServiceEnablementRequired!: boolean;

    @Prop({required: true})
    wbsElement!: string;

    setIdeaCostsAndBenefits!: (value: any) => any;

    ideaStatusId!: number;
    storeCostsAndBenefits!: any;
    isCostsAndBenefitsReadOnly!: boolean;
    initialCostsAndBenefits: any = {};
    costsAndBenefits: any = {};

    endDateErrorMessage: string | null = null;
    startDateErrorMessage: string | null = null;

    costsAndBenefitsIsModified = false;


    created(): void {
        this.costsAndBenefitsIsModified = false;
        this.costsAndBenefits = CostsAndBenefitsTemplate.mapCostsAndBenefitsDto(this.costsAndBenefits, this.storeCostsAndBenefits);
        this.initialCostsAndBenefits = JSON.stringify(this.costsAndBenefits);
        this.checkValidForm();
    }

    @Watch('costsAndBenefits', { deep: true })
    costsAndBenefitsChanged(costsAndBenefitsChanged: CostsAndBenefitsEditDto): void {
        this.setIdeaCostsAndBenefits(costsAndBenefitsChanged);

        const hasChanged = this.initialCostsAndBenefits !== JSON.stringify(this.costsAndBenefits);
        this.$root.$emit('valueOfCostsAndBenefitsChanged', hasChanged);
        this.checkValidForm();

        if (this.$refs.netValue) {
            (this.$refs.netValue as Value).calculateNetValue();
            (this.$refs.netValue as Value).calculateNetPresentValue();
        }
    }

    @Watch('costsAndBenefits.projectStartDate', { deep: true })
    projectStartDateChanged(projectStartDate: any): void {
        if (projectStartDate > moment(this.costsAndBenefits.projectEndDate)) {
            this.startDateErrorMessage = this.$t('costsAndBenefits.startDateErrorMessage').toString();
        } else {
            this.startDateErrorMessage = null;
            this.endDateErrorMessage = null;
            this.checkValidForm();
        }
    }

    @Watch('costsAndBenefits.projectEndDate', { deep: true })
    projectEndDateChanged(projectEndDate: any): void {
        if (projectEndDate < moment(this.costsAndBenefits.projectStartDate)) {
            this.endDateErrorMessage = this.$t('costsAndBenefits.endDateErrorMessage').toString();
        } else {
            this.endDateErrorMessage = null;
            this.startDateErrorMessage = null;
            this.checkValidForm();
        }
    }

    checkValidForm(): void {
        if(this.ideaStatusId >= Status.Prioritized) {
            this.$emit('isValid', !this.checkInvalidForm());
            return;
        }
        this.$emit('isValid', true);
    }

    get canEditCostsAndBenefits(): boolean {
        if(!Permissions.isAdmin())
            return !this.isCostsAndBenefitsReadOnly;
        if(Permissions.canEditCostsAndBenefits(this.ideaStatusId))
            return true;
        return false;
    }

    checkInvalidForm(): boolean {
        return this.costsAndBenefits.deliveryTypeId == null
            || this.costsAndBenefits.deliveryStrategyId == null
            || this.costsAndBenefits.deliveryOrganizationId == null
            || this.costsAndBenefits.strategicThemeId == null
            || this.costsAndBenefits.projectManagerId == null
            || !this.costsAndBenefits.runCostAgreed
            || this.invalidBenefitTypeSelection
            || this.startDateErrorMessage !== null
            || this.endDateErrorMessage !== null
            || !this.costsAndBenefits.serviceRecipientBusinessUnits.every((item: any) => item?.invoicePercent && item?.serviceRecipientBusinessUnitId)
            || this.costsAndBenefits.domainArchitectId == null
            || this.costsAndBenefits.serviceEnablementRequired && !this.costsAndBenefits.serviceEnablementContactId
            || this.invalidIntangibleBusinessBenefits
            || this.invalidBenefitsIT
            || this.invalidDevelopmentAndImplementationCost
            || this.invalidTangibleBusinessBenefits;
    }

    get yearsForecast(): number {
        const projectStartDateYear = moment(this.costsAndBenefits.projectStartDate).year();
        const projectEndDateYear = moment(this.costsAndBenefits.projectEndDate).year();
        return projectEndDateYear - projectStartDateYear;
    }

    get expandCostBenefitOverview(): boolean {
        const { revenue, opex, capex, itBenefits } = this.costsAndBenefits.benefits;
        const { changeCost } = this.costsAndBenefits.costs.changeCost;
        if (this.hasValues(revenue)
            || this.hasValues(opex)
            || this.hasValues(capex)
            || this.hasValues(itBenefits)
            || this.hasValues(changeCost))
            return true;

        this.costsAndBenefits.costs.runCosts.forEach((runCost: any)  => {
            if (this.hasValues(runCost)) return true;
        });

        return false;
    }

    private hasValues(yearValues: CostsAndBenefitsValueItemDto): boolean {
        if (!yearValues) return false;
        return yearValues[Years.year0] != null
            || yearValues[Years.year1] != null
            || yearValues[Years.year2] != null
            || yearValues[Years.year3] != null
            || yearValues[Years.year4] != null
    }

    private handleWbsElementChange(value: number): void {
        this.$emit('wbsChanged', value);
    }

    private isCostsAndBenefitsItemInvalid(item: CostsAndBenefitsValueItem): boolean {
        return Object.values(item).every((key: any) => key === null || key === 0 || key?.length === 0 || key === undefined)
    }

    get invalidBenefitsIT(): boolean {
        return this.isCostsAndBenefitsItemInvalid(this.costsAndBenefits.benefits.itBenefits);
    }

    get invalidDevelopmentAndImplementationCost(): boolean {
        return this.isCostsAndBenefitsItemInvalid(this.costsAndBenefits.costs.changeCost) || !this.costsAndBenefits.runCostAgreed;
    }

    get invalidTangibleBusinessBenefits(): boolean {
        return ['opex','capex','revenue'].some((type: any) => this.isCostsAndBenefitsItemInvalid(this.costsAndBenefits.benefits[type]));
    }

    get invalidIntangibleBusinessBenefits(): boolean {
        return !this.costsAndBenefits.benefits.indirectBenefits?.length
            || this.costsAndBenefits.benefits.indirectBenefits.some((benefit: any) => !benefit.benefitTypeId);
    }

    get invalidBusinessUnits(): boolean {
        return this.showErrorsIfExists && !this.costsAndBenefits.serviceRecipientBusinessUnits?.length
            || this.showErrorsIfExists && !this.costsAndBenefits.serviceRecipientBusinessUnits.every(
                (item: any) => item?.invoicePercent && item?.serviceRecipientBusinessUnitId);
    }

    get invalidDomainArchitect(): boolean {
        return this.showErrorsIfExists && this.costsAndBenefits.domainArchitectId == null;
    }

    get invalidServiceEnablementContact(): boolean {
        return (this.showServiceEnablementRequired || this.showErrorsIfExists ) && (this.costsAndBenefits.serviceEnablementRequired && !this.costsAndBenefits.serviceEnablementContactId);
    }

    get invalidDeliveryOrganization(): boolean {
        return this.showErrorsIfExists && this.costsAndBenefits.deliveryOrganizationId == null;
    }

    get invalidDeliveryStrategy(): boolean {
        return this.showErrorsIfExists && this.costsAndBenefits.deliveryStrategyId == null;
    }

    get invalidDeliveryType(): boolean {
        return this.showErrorsIfExists && this.costsAndBenefits.deliveryTypeId == null;
    }

    get invalidCostCenter(): boolean {
        return this.showErrorsIfExists && this.costsAndBenefits.costCenterId == null;
    }

    get invalidRunCostsAgreed(): boolean {
        return this.showErrorsIfExists && !this.costsAndBenefits.runCostAgreed;
    }

    get invalidStrategicTheme(): boolean {
        return this.showErrorsIfExists && this.costsAndBenefits.strategicThemeId == null;
    }

    get invalidProjectManager(): boolean {
        return this.showErrorsIfExists && this.costsAndBenefits.projectManagerId == null;
    }

    get invalidRunCostSelection(): boolean {
        const validRunCostSelection = this.costsAndBenefits.costs.runCosts.every((runCost: any) => runCost.costTypeId != null)
        return this.showErrorsIfExists && !validRunCostSelection;
    }

    get invalidBenefitTypeSelection(): boolean {
        const validBenefitTypeSelection = this.costsAndBenefits.benefits.indirectBenefits.every((indirectBenefit: any) => indirectBenefit.benefitTypeId != null)
        return this.showErrorsIfExists && !validBenefitTypeSelection;
    }
}
