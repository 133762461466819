
































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'financial-assumptions',
})

export default class FinancialAssumptions extends Vue {
    @Prop({required: true, default: 0 })
    discountRate!: number;

    @Prop({required: true })
    editEnabled!: boolean;

    validateNumber(value: number): void {
        this.$emit('discountRateChange', null);
        if (value > 100) value = 100.00;
        if (value < 0) value = 0.00;
        else value = parseFloat(Number(value).toFixed(2));
        this.$emit('discountRateChange', value);
    }
}
