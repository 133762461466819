






























import { Component, Prop, Vue } from 'vue-property-decorator';
import Currency from '@/utils/helpers/currency';

@Component({
    name: 'currency-input',
})

export default class CurrencyInput extends Vue {
    @Prop({required: true })
    inputValue!: number;

    @Prop({required: false, default: '' })
    name!: string;

    @Prop({required: false })
    readonly placeholder!: string;

    @Prop({required: false, default: false })
    disabled!: any;

    @Prop({required: false, default: true })
    isValid!: boolean;

    @Prop({required: false, default: '' })
    error!: string;

    private focused = false;

    private get value(): string {
        return this.inputValue === null || this.inputValue === undefined
            ? ''
            : `${this.inputValue}`;
    }

    private set value(changedValue: string ) {
        changedValue === ''
            ? this.$emit('change', undefined)
            : this.$emit('change', Number(Number(changedValue).toFixed(2)));
    }

    private numberToCurrency(): string | undefined {
        return Currency.numberToCurrencyWithFormat(this.inputValue);
    }

    private inputFocus(): void {
        if (this.disabled) return;
        this.focused = true;
    }

    private onBlur(): void {
        this.focused = false;
        this.$emit('blur');
    }
}
