





















































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';

import {
    IScreenDto, QuestionDto,
    IAnswerDto, IAnswerNestedEditDto,
    AnswerNestedCreateDto,
    AnswerValueNestedEditDto,
    AnswerNestedEditDto,
    CommentNestedCreateDto,
    AnswerCommentNestedEditDto,
    AnswerCommentNestedCreateDto,
    CommentNestedEditDto
} from '@/service-proxies/service-proxies.g';

import formFieldMap from '@/pages/submit/formFieldMap';
import * as customControls from '@/components/custom-form';

import { UserService } from '@/services/user-service';
import { UserFilterModel } from '@/models';

const NOT_AVAILABLE = 'N/A'

const SCORE_COLOR = {
    0: '#ADADAD',
    1: '#0059A4',
    2: '#0067cb',
    3: '#0078DC',
    4: '#00A7F5',
}

export const ScreenNames = {
    'Review': 'Screening',
    'Prioritize': 'Preparation & Prioritization',
    'Stake Holders': 'Stakeholders mapping',
}

@Component({
    name: 'Screen',
    components: {
        ...customControls,
    },
})
export default class Screen extends Vue {
    $store: any;

    @Prop()
    private disabled!: boolean;

    @Prop()
    answers!: IAnswerNestedEditDto[];

    @Prop()
    private screenId: any;

    @Prop()
    private screenName!: string;

    @Prop()
    private shouldMarkInvalid!: boolean;

    private validating = false;

    private scores: any[] = [];

    private filteredUsers: {label: string; email: string; secondaryLabel: string; value: string}[] = []
    private usersAreLoading = false
    private downloadedUsers: {label: string; email: string; secondaryLabel: string; value: string}[] = []

    private async mounted(): Promise<void> {
        if (this.questions && this.questions.length > 0) {
            for (const question of this.questions) {
                if(this.formFieldIsUserDropdown(question)) {
                    await this.downloadUserDropdownAnswer(question)
                }
            }
        }
    }

    private async onUserTypeAheadSearch(e: any): Promise<void> {
        this.filteredUsers = await this.usersFiltered(e)
    }

    private onUserTypeAheadAbort(): void {
        this.usersAreLoading = false
    }

    private async usersFiltered(byTerm: string | undefined): Promise<{label: string; email: string; secondaryLabel: string; value: string}[]> {
        const userFilterModel: UserFilterModel = {
            page: 1,
            size: 300,
            term: byTerm ? byTerm : '',
            userGroupId: null,
            sortColumn: 1,
            sortDirection: 'Ascending',
        };

        this.usersAreLoading = true

        const users = await new UserService().getAllPaged(userFilterModel);
        let userList = [];
        userList = users && users.result ? users.result.items : [];

        const usersMappedForDropdown: {label: string; email: string; secondaryLabel: string; value: string}[] = userList.map((user: any) => ({
            label: user.name || user.email || user.kid,
            email: user.email,
            secondaryLabel: user.kid,
            value: `${user.id}`
        }))

        this.downloadedUsers = this.downloadedUsers.concat(usersMappedForDropdown)
        this.downloadedUsers = [...new Set(this.downloadedUsers)]

        this.usersAreLoading = false

        return usersMappedForDropdown
    }

    private async getUserValueForValue(value: string): Promise<{label?: string; email?: string; secondaryLabel?: string; value?: string} | undefined> {
        for (const user of this.downloadedUsers) {
            if (user.label == value || user.secondaryLabel == value) {
                return user
            }
        }

        const userFilterModel: UserFilterModel = {
            page: 1,
            size: 1,
            term: value,
            userGroupId: null,
            sortColumn: 1,
            sortDirection: 'Ascending',
        };

        this.usersAreLoading = true

        const users = await new UserService().getAllPaged(userFilterModel);
        this.usersAreLoading = false

        if (users && users.result && users.result.items && users.result.items.length > 0) {
            const user = users.result.items[0]
            const userObject: {label: string; email: string; secondaryLabel: string; value: string} = {
                label: user.name || user.email || user.kid || '',
                email: user.email,
                secondaryLabel: user.kid || '',
                value: `${user.id}`,
            }

            this.downloadedUsers.push(userObject)
            this.downloadedUsers = [...new Set(this.downloadedUsers)]

            return userObject
        }

        return undefined
    }

    downloadUserDropdownAnswer(question: QuestionDto): void {
        const downloadedAnswer = this.getUserDropdownAnswer(question)

        if (!downloadedAnswer) {
            const answers = this.answers
            const existingAnswer = answers.find((answer: any) => answer.questionId === question.id)

            if (existingAnswer && existingAnswer.content) {
                this.getUserValueForValue(existingAnswer.content)
            }
        }
    }

    private getUserDropdownAnswer(question: QuestionDto): any | undefined {
        const answers = this.answers
        const existingAnswer = answers.find((answer: any) => answer.questionId === question.id)

        if (existingAnswer && existingAnswer.content) {
            for (const user of this.downloadedUsers) {
                if (user.label == existingAnswer.content || user.secondaryLabel == existingAnswer.content) {
                    return user
                }
            }
        }

        return undefined
    }

    private get isPartOfGroup(): boolean {
        return !!~(this.screenName || '').indexOf('/');
    }

    private getAnswers(): void {
        function cleanAnswerValues(answer: any): any {
            const answerValues = (answer.answerValues || []).map((v: any) => new AnswerValueNestedEditDto({
                valueId: v.valueId,
            })).filter((v: any) => v.valueId)

            return answerValues.length ? answerValues : [...answerValues]
        }

        this.answers = (this.$store.state.ideas.idea.answers || []).map((answer: IAnswerDto) => ({
            questionId: answer.questionId,
            content: answer.content,
            answerValues: cleanAnswerValues(answer),
            id: answer.id,
            ideaId: answer.ideaId,
        }));
    }

    get questions(): QuestionDto[] | undefined {
        let questions = (this.$store.state.idea.screens
            .find((screen: IScreenDto) => screen.id === this.screenId) || {})
            .questions || []
        const lastItem = questions[questions.length - 1]

        if (lastItem && lastItem.questionType.name === 'ScreenTotalCalculation') {
            questions = [lastItem, ...questions.slice(0, questions.length - 1)];
        }

        return questions
    }

    // questions
    getAnswer(question: QuestionDto): string | number | undefined {
        const answers = this.answers

        const existingAnswer = answers.find((answer: any) => answer.questionId === question.id)

        if ((question.questionType as any).name === 'DropDownUsers') {
            const match = this.$store.state.user.users.find((user: any) => {
                return user.label === (existingAnswer && existingAnswer.content)
            })

            return (match || {}).value
        }
        return existingAnswer
            && existingAnswer.answerValues
            && existingAnswer.answerValues[0]
            && existingAnswer.answerValues[0].valueId
            ||
            existingAnswer && existingAnswer.content
    }

    getAnswerComments(question: QuestionDto):  string | undefined {
        const answers = this.answers
        const existingAnswer = answers.find((answer: any) => answer.questionId === question.id)
        let comments:  string | undefined
        if(existingAnswer && existingAnswer.answerComments?.length){
            const  answercomments = existingAnswer.answerComments;
            comments = answercomments[0].comment?.text
        }
        return comments

    }

    getMultiSelectAnswerValues(question: QuestionDto): (number | undefined)[] {
        const answers = this.answers
        const existingAnswer = answers.find((answer: any) => answer.questionId === question.id)
        const multiSelectValues: (number|undefined)[] = [] ;

        if(existingAnswer){
            const  answerValues = existingAnswer.answerValues;
            answerValues?.forEach(item => {
                multiSelectValues.push(item.valueId)
            })
        }
        return multiSelectValues

    }

    getAnswerContent(question: QuestionDto): string | undefined {
        return (this.answers.find(answer => answer.questionId === question.id) || {}).content
    }

    getScore(question: QuestionDto): any {
        const valueId = this.getAnswer(question)
        const values = question.values || []

        const score = values.find && (values.find(value => value.id === valueId) || {}).score

        if (typeof score !== 'undefined') {
            const scores = this.scores || []
            //
            if (!scores.find(score => score.id === valueId)) {
                this.scores = (scores).concat([{
                    id: valueId,
                    score,
                    max: Math.max(...values.map(value => value.score || 0)),
                }]);

                this.scores.push({
                    score: question.score || 1,
                });

                this.$store.commit('idea/setScore', {
                    id: this.screenId,
                    value: this.scores,
                });
            }
            return {
                value: score,
                color: (SCORE_COLOR as any)[score],
            }
        }
    }

    getWeight(question: QuestionDto): any {
        return (question.score || 0) > 1
            ? question.score
            : ''
    }

    hasAnswer(question: QuestionDto): boolean {
        const answers = this.answers
        const existingAnswer = answers.find((answer: any) => answer.questionId === question.id)

        if(this.screenName === 'Stake Holders'){

            return (existingAnswer && (existingAnswer.content !== NOT_AVAILABLE ) || (!existingAnswer))

        }else{

            return !!(existingAnswer && (
                existingAnswer.answerValues && existingAnswer.answerValues.length
                ||
                existingAnswer.content !== NOT_AVAILABLE
            ))
        }
    }

    getCheckedForRadio(question: QuestionDto, answer: any): boolean {
        return (this.answers.find(answer => answer.questionId === question.id) || {}).content == answer
    }

    getCheckedForAnswer(question: QuestionDto, answerID: number): boolean {
        const currentAnswer = this.answers.find(answer => answer.questionId === question.id)
        let answerIsChecked = false

        if (currentAnswer !== undefined) {
            const aValues = currentAnswer.answerValues
            if (aValues !== undefined && aValues.length > 0) {
                answerIsChecked = aValues.find(answerValue => answerValue.valueId === answerID) ? true : false
            }
        }

        return answerIsChecked
    }

    setAnswer(question: QuestionDto, answer: string): void {
        const answers = this.answers
        const existingAnswer = answers.find((answer: any) => answer.questionId === question.id)

        if (existingAnswer) {
            existingAnswer.content = answer
        } else {
            answers.push(new AnswerNestedCreateDto({
                questionId: question.id,
                content: answer,
            }))
        }
    }

    setUserDropdownAnswer(question: QuestionDto, users: any): void {
        if (users && users.length > 0) {
            const answers = this.answers
            const existingAnswer = answers.find((answer: any) => answer.questionId === question.id)

            if (existingAnswer) {
                existingAnswer.content = users[0].secondaryLabel
            } else {
                answers.push(new AnswerNestedCreateDto({
                    questionId: question.id,
                    content: users[0].secondaryLabel,
                }))
            }

        }
    }

    setValueChecked(question: QuestionDto, valueId: number, isChecked: boolean): void {
        const answers = this.answers
        const existingAnswer = answers.find((answer: any) => answer.questionId === question.id)
        const existingAnswerValue = existingAnswer ? existingAnswer.answerValues || [] : []

        const valueAlreadyThere = existingAnswerValue.find((aval: any) => aval.valueId === valueId)

        if (isChecked && valueAlreadyThere === undefined) {
            existingAnswerValue.push(new AnswerValueNestedEditDto({
                valueId: valueId,
                answerId: existingAnswer && (existingAnswer as AnswerNestedEditDto).id,
            }))

            if (existingAnswer) {
                existingAnswer.answerValues = existingAnswerValue
            } else {
                answers.push(new AnswerNestedCreateDto({
                    questionId: question.id,
                    answerValues: existingAnswerValue,
                }))
            }
        } else if (!isChecked && valueAlreadyThere !== undefined) {
            existingAnswerValue.splice(existingAnswerValue.findIndex(i => i.valueId === valueId), 1)

            if (existingAnswer) {
                existingAnswer.answerValues = existingAnswerValue
            } else {
                answers.push(new AnswerNestedCreateDto({
                    questionId: question.id,
                    answerValues: existingAnswerValue,
                }))
            }
        }
    }

    setValue(question: QuestionDto, e: any): void {
        if(this.formFieldIsMultiSelectDropdown(question)){
            this.setMultiSelectDropdownValue(question, e)
        } else if (this.formFieldIsDropdown(question)) {
            this.setAnswerValue(question, e)
        }
        else {
            const el = (e.target as HTMLFormElement)
            const value = el.checked ? el.value : NOT_AVAILABLE

            if (e.composed) {
                const answers = this.answers
                const existingAnswer = answers.find((answer: any) => answer.questionId === question.id)

                if (existingAnswer) {
                    existingAnswer.content = value
                } else {
                    answers.push({
                        questionId: question.id,
                        content: value,
                        answerValues: [],
                    })
                }
            }
        }
    }

    setAnswerValue(question: QuestionDto, answerValue: any): void {
        const answers = this.answers
        const existingAnswer = answers.find((answer: any) => answer.questionId === question.id)
        let existingAnswerValue = existingAnswer ? existingAnswer.answerValues || [] : []

        existingAnswerValue = [new AnswerValueNestedEditDto({
            valueId: answerValue,
            id: (existingAnswerValue[0] || {}).id,
            answerId: (existingAnswerValue[0] || {}).answerId,
        })]

        if (existingAnswer) {
            existingAnswer.answerValues = existingAnswerValue
        } else {
            answers.push(new AnswerNestedCreateDto({
                questionId: question.id,
                answerValues: existingAnswerValue,
            }))
        }
        this.scores = []
    }

    setAnswerComments(question: QuestionDto, passedValue: any): void {
        const answers = this.answers
        const existingAnswer = answers.find((answer: any) => answer.questionId === question.id)
        const createdComment = new CommentNestedCreateDto
        createdComment.text = passedValue;
        const editedComment = new CommentNestedEditDto
        editedComment.text = passedValue;
        const existingAnswerComments = existingAnswer ? existingAnswer.answerComments || [] : []

        const newAnswerComment =  [new AnswerCommentNestedCreateDto({
            comment:createdComment,
        })]

        if(existingAnswer){
            if(existingAnswerComments.length){
                const answerComment=[new AnswerCommentNestedEditDto({
                    comment:editedComment,
                    id: (existingAnswerComments[0] || {}).id,
                    answerId: (existingAnswer || {}).id,
                    commentId:(existingAnswerComments[0] || {}).commentId
                })]
                existingAnswer.answerComments = answerComment
            }else{
                existingAnswer.answerComments = newAnswerComment
            }
        }
    }

    setMultiSelectDropdownValue(question: QuestionDto, e: any): void {
        const values = e
        const answers = this.answers
        const existingAnswer = answers.find((answer: any) => answer.questionId === question.id)
        const existingAnswerValue: AnswerValueNestedEditDto[]|undefined = []

        values.forEach((value: any, index: number) => {
            if(existingAnswer?.answerValues)
                existingAnswerValue.push(new AnswerValueNestedEditDto({
                    valueId: value,
                    id: (existingAnswer.answerValues[index] || {}).id,
                    answerId: (existingAnswer.answerValues[index] || {}).answerId,
                }))
        });

        if (existingAnswer) {
            existingAnswer.answerValues = existingAnswerValue
        } else {
            answers.push(new AnswerNestedCreateDto({
                questionId: question.id,
                answerValues: existingAnswerValue,
            }))
        }
        this.scores = []
    }

    // form fields
    formField(field: QuestionDto): string | undefined {
        return (field.questionType as any).name && formFieldMap[(field.questionType as any).name]
    }

    formFieldIsList(field: QuestionDto): boolean {
        return field && field.questionType && field.questionType.name &&
            !!~field.questionType.name.indexOf('List')
            || false
    }

    mapItems(values: any): any {
        return values.map((item: any) => ({
            label: item.content,
            value: item.id,
        }))
    }

    // validation
    isValid(): boolean | undefined {
        this.validating = true;
        return this.questions && this.questions.every(this.questionIsValid)
    }

    private questionIsValid(question: QuestionDto): boolean {
        return question.isRequired
            ? !!this.getAnswer(question) || !!this.getAnswerContent(question)
            : true
    }

    private formFieldIsDropdown(field: QuestionDto): boolean {
        return (field.questionType as any).name.indexOf('DropDown') > -1
    }

    private formFieldIsMultiSelectDropdown(field: QuestionDto): boolean{
        return (field.questionType as any).name.indexOf('MultiselectDropDown') > -1
    }

    private formFieldIsCheckbox(field: QuestionDto): boolean {
        return (field.questionType as any).name.indexOf('CheckboxList') > -1
    }

    private formFieldIsUserDropdown(field: QuestionDto): boolean {
        return (field.questionType as any).name.indexOf('DropDownUsers') > -1
    }
}

