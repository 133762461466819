


































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {
    IIdeaCommentNestedEditDto,
} from '@/service-proxies/service-proxies.g';
import { actions, getters } from '@/store/types';
import { mapGetters } from 'vuex';


@Component({
    name: 'widget-comments',
    computed: mapGetters({
        userKid: getters.GET_USER_KID,
        isAdmin: getters.USER_IS_ADMIN,
    }),
})
export default class WidgetComments extends Vue {
    $store: any;
    userKid!: any;
    isAdmin!: any;

    private validating = false;
    private maxCharactersLength = 200;

    @Prop()
    comments!: IIdeaCommentNestedEditDto[];

    private get idea(): any {
        return this.$store.state.ideas.idea;
    }

    private preventKeydownCapture(e: any): void {
        if (e.code === 'Enter') {
            this.submitComment();
        }
        e.stopPropagation();
    }

    private ideaComment = '';

    private async submitComment(): Promise<void> {
        this.validating = true;

        if (!this.ideaComment) return

        if(!this.isEmpty(this.ideaComment)){
            await this.$store.dispatch(actions.SUBMIT_IDEA_COMMENT, {
                text: this.ideaComment,
                ideaId: this.idea.id,
            })
            this.$emit('commentAdded');

            this.validating = false;
            this.ideaComment = '';
        }
    }

    @Watch('comments')
    private onCommentsChanged(): void {
        setTimeout(() => {
            (this.$refs.commentsList as HTMLElement).scrollTop = 99999;
        }, 100);
    }

    private getAuthor(comment: any): string {
        if (comment.comment.createdByEmail === this.$store.state.user.user.email) {
            return (this.$t('you') as string);
        }
        return comment.comment.createdByFullName || comment.comment.createdBy
    }

    private shouldShowDate(comment: any, i: number): boolean {
        const previousComment = this.comments[i - 1] && (this.comments[i - 1].comment as any);
        const previousCommentDay = previousComment
            ? previousComment.createdAt && previousComment.createdAt.getDay()
            : null;

        const commentDay = comment.comment.createdAt.getDay() || null;
        if (previousCommentDay === commentDay) {
            return false;
        }

        return true;
    }

    canDeleteComment(commentAuthorKid: string): boolean {
        return this.isAdmin || commentAuthorKid === this.userKid;
    }

    private async deleteIdeaComment(commentId: string): Promise<void> {
        if(confirm(this.$t('deleteConfirmationIdeaComment') as string) ) {
            const deletedSuccessfully = await this.$store.dispatch(actions.DELETE_IDEA_COMMENT, commentId)

            if (deletedSuccessfully) {
                this.$emit('commentDeleted');
            }
        }
    }

    private isEmpty(comment: string): boolean {
        return comment.replace(/^\s+|\s+$/g, '').length == 0;
    }
}

